import Gradient1 from './gradient-1.svg';
import Gradient2 from './gradient-2.svg';
import Gradient3 from './gradient-3.svg';
import Gradient4 from './gradient-4.svg';

export const skins = {
  'gradient-1': Gradient1,
  'gradient-2': Gradient2,
  'gradient-3': Gradient3,
  'gradient-4': Gradient4,
};

export const getSkin = (skin) => {
  if (!skin) return skins['gradient-1'];
  return skins[skin];
};
