import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useNavigate } from 'react-router-dom';
import Button from '../components/util/Button';
import { validate } from 'react-email-validator';
import Logo from '../assets/icons/logo.svg';

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(null);

  const register = async () => {
    console.log('registering');
    if (loading) return;
    if (password != passwordConfirm) {
      return setError('Passwords do not match');
    }
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/auth/register',
        {
          email,
          password,
        }
      );
      const token = response.data.jwt;
      if (!token) {
        dispatch(setLoading(false));
        return setError('Server error. Please try again');
      }
      localStorage.setItem('auth_token', token);
      dispatch(setLoading(false));
      navigate('/dashboard/campaign');
    } catch (e) {
      console.log(e.response);
      dispatch(setLoading(false));
      setError(e.response.data.error.msg);
    }
  };

  return (
    <main className="main mb-10 p-4 w-full max-w-2xl mx-auto flex flex-col items-center">
      <img src={Logo} className="w-[48px] mt-20" />
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">Register</h2>
      <div className="mt-2 text-gray-500 text-center">
        Welcome back! Please enter your login details
      </div>
      <div className="text-red-500 text-sm mt-2">
        Note: We are currently operating our closed beta. Only users who have
        been notified of access to the Beta will be able to use CouldBeFans.
      </div>
      <div className="form mt-8 w-full">
        <div className="input mt-3">
          <label>Email</label>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={email}
          />
        </div>
        <div className="input mt-3">
          <label>Password</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={password}
          />
        </div>
        <div className="input mt-3">
          <label>Confirm Password</label>
          <input
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Confirm Password"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={passwordConfirm}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col items-center">
        <Button
          title="Register"
          onClick={() => {
            register();
          }}
          type="action"
          disabled={
            password != passwordConfirm &&
            password.length < 8 &&
            validate(email)
          }
          className="w-full"
        />
        {error && <span className="block text-red-500 mt-1">{error}</span>}
      </div>
    </main>
  );
}

export default Register;
