import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './uiSlice';
import campaignReducer from './campaignSlice';
import mediaReducer from './mediaSlice';
import userReducer from './userSlice';

export default configureStore({
  reducer: {
    ui: uiReducer,
    campaign: campaignReducer,
    media: mediaReducer,
    user: userReducer,
  },
});
