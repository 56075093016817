import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { getSubscribers } from '../api/subscribers';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Email',
    selector: (subscriber, index) => {
      return subscriber.email;
    },
  },
  {
    name: 'Subscribed On',
    selector: (subscriber, index) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(subscriber.verified_at);
      return date.toLocaleDateString('en-US', options);
    },
  },
];

const SubscriberList = () => {
  const dispatch = useDispatch();

  const [subscribers, setSubscribers] = useState(null);

  const loadSubscribers = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getSubscribers();
      setSubscribers(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    loadSubscribers();
  }, []);

  if (subscribers == null) return null;

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col items-center pt-4">
      <div className="w-full flex flex-col items-center gap-4">
        <div className="flex items-center gap-2">
          <div className="text-[36px] text-cbf-primary">
            {subscribers.count}
          </div>
          <div className="max-w-[150px] text-center">
            Verified Real Addresses
          </div>
        </div>
        <DataTable title="" columns={columns} data={subscribers.subscribers} />
      </div>
    </main>
  );
};

export default SubscriberList;
