import React from 'react';

const Toggle = ({ value, onToggle }) => {
  return (
    <div
      className={`${
        value ? 'bg-cbf-primary justify-end' : 'bg-gray-300 justify-start'
      } flex h-[20px] w-[36px] p-[1px] rounded-full items-center cursor-pointer`}
      onClick={() => {
        onToggle();
      }}
    >
      <div className="rounded-full bg-white w-[16px] h-[16px]"></div>
    </div>
  );
};

export default Toggle;
