import React from 'react';
import Logo from '../../assets/icons/logo.svg';
const Header = () => {
  return (
    <header className="h-[60px] w-full bg-white border-b-[1px] border-gray-200 flex py-2 px-4 items-center justify-between">
      <div className="flex items-center">
        <img src={Logo} className="max-h-[40px]" />
        <div className="ml-2 text-gray-900 font-semibold">CouldBeFans</div>
      </div>
      <div className="text-gray-700 flex items-center gap-4">
        <a href="#howitworks" className="text-sm cursor-pointer">
          How it works
        </a>
        <a href="#waitlist" className="text-sm cursor-pointer">
          Waitlist
        </a>
        {/* <a
          href="https://blog.couldbefans.com"
          target="_blank"
          className="text-sm cursor-pointer"
        >
          Blog
        </a> */}
      </div>
    </header>
  );
};

export default Header;
