import React from 'react';
import { mediaUrl } from '../../api/media';

const ProfileMedia = ({ media }) => {
  if (!media) {
    return (
      <div className={`w-full aspect-square bg-cbf-primary rounded-full`}></div>
    );
  }

  return (
    <div
      className={`w-full aspect-square bg-cbf-primary rounded-full overflow-hidden`}
    >
      <img src={mediaUrl(media)} className="w-full h-full object-cover" />
    </div>
  );
};

export default ProfileMedia;
