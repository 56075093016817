import React, { useState, useEffect } from 'react';
import { unsubscribe } from '../api/subscribe';
import { useDispatch } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useParams } from 'react-router-dom';

const Unsubscribe = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [error, setError] = useState(null);

  useEffect(() => {
    const id = params.id;
    (async () => {
      dispatch(setLoading('OPAQUE'));
      try {
        await unsubscribe(id);
      } catch (e) {
        console.log(e);
        setError(e);
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [params]);

  if (error) {
    return <div>Error unsubscribing</div>;
  }

  return <div>Successully unsubscribed</div>;
};

export default Unsubscribe;
