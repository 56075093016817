import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/icons/logo.svg';
import Button from '../components/util/Button';
import { validate } from 'react-email-validator';

function Auth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const login = async () => {
    if (loading) return;
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/auth/login',
        {
          email,
          password,
        }
      );
      const token = response.data.jwt;
      if (!token) {
        dispatch(setLoading(false));
        return setError('Server error. Please try again');
      }
      localStorage.setItem('auth_token', token);
      dispatch(setLoading(false));
      navigate('/dashboard/campaign');
    } catch (e) {
      //console.log(e.response);
      dispatch(setLoading(false));
      setError('Invalid email / password combination');
      //setError(e.response.data.error);
    }
  };

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col items-center">
      <img src={Logo} className="w-[48px] mt-20" />
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">Login</h2>
      <div className="mt-2 text-gray-500 text-center">
        Welcome back! Please enter your login details
      </div>
      <div className="mt-8 w-full">
        <label className="text-gray-700">Email</label>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="block w-full mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
          value={email}
        />
      </div>
      <div className="input mt-6 w-full">
        <label className="text-gray-700">Password</label>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="block w-full mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
          value={password}
        />
      </div>
      <div className="flex justify-end w-full mt-6">
        <div
          className="cursor-pointer text-cbf-primary font-semibold"
          onClick={() => {
            navigate('/dashboard/forgot-password');
          }}
        >
          Forgot password
        </div>
      </div>
      <Button
        type="action"
        onClick={() => {
          login();
        }}
        className="w-full mt-6"
        disabled={!validate(email) || password.length < 8}
        title="Login"
      />
      {error && <span className="block text-red-500 mt-1">{error}</span>}
    </main>
  );
}

export default Auth;
