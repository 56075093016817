import React from 'react';
import Pulse from '../../assets/icons/pulse.svg';

const PulseIcon = ({ children, className }) => {
  return (
    <div className={`relative ${className}`}>
      <img src={Pulse} className="h-full w-full" />
      <div className="absolute top-0 left-0 right-0 bottom-0">{children}</div>
    </div>
  );
};

export default PulseIcon;
