import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../state/uiSlice';
import Logo from '../../assets/icons/logo.svg';
import Button from '../../components/util/Button';
import AdminCreators from './AdminCreators';
import AdminMedia from './AdminMedia';
import AdminStats from './AdminStats';

const tabs = ['Creators', 'Media', 'Stats'];

const Admin = () => {
  const [tab, setTab] = useState('Media');

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col items-center">
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">Admin</h2>
      <div className="flex justify-evenly w-full gap-4 mb-10 mt-10">
        {tabs.map((item) => {
          return (
            <Button
              type={`${item == tab ? 'action' : 'light'}`}
              title={item}
              className="w-full"
              onClick={() => {
                setTab(item);
              }}
              key={item}
            />
          );
        })}
      </div>
      {tab == 'Creators' ? (
        <AdminCreators />
      ) : tab == 'Media' ? (
        <AdminMedia />
      ) : (
        <AdminStats />
      )}
    </main>
  );
};

export default Admin;
