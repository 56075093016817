import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuVisible } from '../state/uiSlice';
import Logo from '../assets/icons/logo.svg';

const Header = () => {
  const dispatch = useDispatch();
  const menuVisible = useSelector((state) => state.ui.menuVisible);

  return (
    <div className="w-full flex p-3 items-center justify-between lg:hidden">
      <div className="flex items-center justify-center gap-2">
        <img className="h-[18px]" src={Logo} alt="CBF" />
        <p className="text-xl font-semibold">Could Be Fans</p>
      </div>
      <div
        className="lg:hidden"
        onClick={() => {
          dispatch(setMenuVisible(!menuVisible));
        }}
      >
        <button className="navbar-burger flex items-center fill-gray-500 p-3">
          <svg
            className="block h-4 w-4 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Header;
