import React from 'react';
import { useSearchParams } from 'react-router-dom';

const AdTracker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const env = process.env.REACT_APP_ENVIRONMENT_NAME;
  const random = Math.floor(Date.now() * Math.random());
  const campaignId = searchParams.get('campaign');
  console.log('campaignId:', campaignId);
  return (
    <>
      {env == 'prod' && campaignId ? (
        <img
          id="1000420861_cpa_testing"
          src={`https://ads.trafficjunky.net/tj_ads_pt?a=1000420861&member_id=1006046301&cb=
${random}&cti=${random}&ctd=page_view`}
          width="1"
          height="1"
          border="0"
        />
      ) : null}
    </>
  );
};

export default AdTracker;
