import React from 'react';
import Copy from '../assets/icons/copy.svg';
import { useSelector } from 'react-redux';
import UserHeader from './UserHeader';
import SubscribeLinkCopy from './SubscribeLinkCopy';

const CampaignHeader = () => {
  const profile = useSelector((state) => state.user.profile);
  const campaign = useSelector((state) => state.campaign);
  const validateCampaign = () => {
    for (let index in campaign.items) {
      if (Object.keys(campaign.items[index]).length == 0) {
        return false;
      }
      if (!campaign.items[index].media.verified) {
        return false;
      }
      break;
    }
    return true;
  };

  const isCampaignValid = validateCampaign();

  return (
    <div>
      <UserHeader profile={profile} />
      <div className="px-4 flex flex-col justify-end pb-4">
        <div className="font-semibold text-xl mt-4">Your Campaign</div>
        {!isCampaignValid ? (
          <div className="font-regular text-sm text-gray-600">
            Your campaign will go live once all 5 days are complete and their
            media has been verified.
          </div>
        ) : (
          <SubscribeLinkCopy username={profile?.only_fans} />
        )}
      </div>
    </div>
  );
};

export default CampaignHeader;
