import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Link, useLocation } from 'react-router-dom';
import CookieConsent from './components/CookieConsent';
import LoadingOverlay from './components/util/LoadingOverlay';
import Login from './pages/Login';
import Unverified from './pages/Unverified';
import Campaign from './pages/Campaign';
import Analytics from './pages/Analytics';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import Subscribe from './pages/Subscribe';
import SubscribeVerify from './pages/SubscribeVerify';
import View from './pages/View';
import Report from './pages/Report';
import Home from './pages/home/Home';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import Unsubscribe from './pages/Unsubscribe';
import Footer from './components/Footer';
import TermsOfUse from './pages/legal/TermsOfUse';
import TermsOfService from './pages/legal/TermsOfService';
import USC2257 from './pages/legal/USC2257';
import CCPAPolicy from './pages/legal/CCPAPolicy';
import DMCAPolicy from './pages/legal/DMCAPolicy';
import ComplaintPolicy from './pages/legal/ComplaintPolicy';
import CreatorAgreement from './pages/CreatorAgreement';
import Media from './pages/Media';
import Account from './pages/Account';
import Help from './pages/Help';
import Register from './pages/Register';
import GlobalUnsubscribe from './pages/GlobalUnsubscribe';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Setup from './pages/setup/Setup';
import Admin from './pages/admin/Admin';
import SubscriberList from './pages/SubscriberList';

const App = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith('/ccpa-request')) {
      window.location.href =
        'https://docs.google.com/forms/d/e/1FAIpQLSdjZzVf7BCx-ZJDduB4SHfiwetMY_GufGhtxdPgAXMuFsvLbg/viewform';
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-center font-sans">
      <Routes>
        <Route
          exact
          path="/subscribe/verify/:id"
          element={<SubscribeVerify />}
        />
        <Route exact path="/subscribe/:username" element={<Subscribe />} />
        <Route exact path="/view/:id" element={<View />} />
        <Route exact path="/unsubscribe" element={<GlobalUnsubscribe />} />
        <Route exact path="/unsubscribe/:id" element={<Unsubscribe />} />
        <Route exact path="/report/:id" element={<Report />} />
        <Route exact path="/dashboard" element={<Dashboard />}>
          <Route exact path="/dashboard/login" element={<Login />} />
          <Route
            exact
            path="/dashboard/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/dashboard/password-reset/:id"
            element={<PasswordReset />}
          />
          <Route exact path="/dashboard/register" element={<Register />} />
          <Route
            exact
            path="/dashboard/agreement"
            element={<CreatorAgreement />}
          />
          <Route exact path="/dashboard/unverified" element={<Unverified />} />
          <Route
            exact
            path="/dashboard/setup"
            element={
              <ProtectedRoute>
                <Setup />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/campaign"
            element={
              <ProtectedRoute>
                <Campaign />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/media"
            element={
              <ProtectedRoute>
                <Media />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/subscribers"
            element={
              <ProtectedRoute>
                <SubscriberList />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/help"
            element={
              <ProtectedRoute>
                <Help />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/admin" element={<Admin />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/statement-2257" element={<USC2257 />} />
        <Route path="/ccpa-policy" element={<CCPAPolicy />} />
        <Route path="/dmca-policy" element={<DMCAPolicy />} />
        <Route path="/complaint-policy" element={<ComplaintPolicy />} />
        <Route path="/ccpa-request" element={<p>Redirecting to form...</p>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <LoadingOverlay />
      <CookieConsent />
    </div>
  );
};

export default App;
