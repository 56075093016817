import React, { useState } from 'react';
import { validate } from 'react-email-validator';
import { globalUnsubscribe } from '../api/subscribe';
import Button from '../components/util/Button';

const GlobalUnsubscribe = ({}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const submit = async () => {
    setLoading(true);
    try {
      await globalUnsubscribe(email);
      setSubmitted(true);
    } catch (e) {
      console.log(e);
      setError('Error unsubscribing. Please contact help@couldbefans.com');
    }
    setLoading(false);
  };

  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col">
      <div className="p-4">
        <div className="font-semibold mt-10 text-xl">Unsubscribe</div>
        {!submitted ? (
          <>
            <div className="text-gray-700">
              Unsubscribing from all email campaigns.
            </div>
            <input
              className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block mt-2"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Button
              title="Submit"
              type="action"
              className="w-full mt-4"
              disabled={!validate(email)}
              onClick={() => {
                submit();
              }}
              loading={loading}
            />
            {error && <div className="text-red-500">{error}</div>}
          </>
        ) : (
          <div className="">Successfully unsubscribed.</div>
        )}
      </div>
    </main>
  );
};

export default GlobalUnsubscribe;
