import React from 'react';
import PulseIcon from '../../components/util/PulseIcon';
import SmallCheck from '../../assets/icons/small-check.svg';

const InactiveStep = ({ title, number, completed }) => {
  return (
    <div className="flex items-center">
      <PulseIcon className="w-[40px] h-[40px]">
        {completed ? (
          <div className="w-full h-full flex items-center justify-center">
            <img src={SmallCheck} className="w-[20px] h-[20px]" />
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-cbf-primary font-semibold">
            {number}
          </div>
        )}
      </PulseIcon>
      <div className="font-semibold text-gray-800 text-[20px] pl-4">
        {title}
      </div>
    </div>
  );
};

export default InactiveStep;
