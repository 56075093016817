export default `
**Privacy Policy**

**Last Updated: 3/9/2023**

**Introduction**

Innumerable LLC (" **Company**" or " **We**") respect your privacy and are committed to protecting it through our compliance with this policy.

This policy describes the types of information we might collect from you or that you might provide when you visit the website or through services associated with [couldbefans.com](http://www.couldbefans.com/) and its subdomains (our " **Website**") and our practices for collecting, using, maintaining, protecting, and disclosing that information.

This policy applies to information we collect:

- On this Website.
- In email, text, and other electronic messages between you and this Website.

It does not apply to information collected by:

- Us offline or through any other means, including on any other website operated by Company or any nonparty; or
- Any nonparty, including through any application or content (including advertising) that might link to or be accessible from or through the Website.

Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this Privacy Policy. We may change this policy from time to time (see[Changes to Our Privacy Policy](#bookmark=id.2et92p0)). We consider your continued use of this Website after we make changes to be acceptance of those changes, so please check the policy periodically for updates.

**Children Under the Age of 18**

Our Website is not intended for children under 18 years old. No one under 18 years old may provide any information to or on the Website. We do not knowingly collect personal information from children under 18 years old. If you are under 18, do not use or provide any information on this Website or through any of its features, register on the Website, make any purchases through the Website, use any interactive or public comment features of this Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you might use. If we learn we have collected or received personal information from a child under 18, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at[privacy@couldbefans.com](mailto:privacy@couldbefans.com).

**Information We Collect About You and How We Collect It**

We collect several types of information from and about users of our Website that we have grouped together as follows:

- **Identity Data** includes first name, last name, username or similar identifier, social media identifiers, government-issued photo identification, date of birth, gender, selfie photo, and voice and image (in any content that you post or transmit).
- **Contact Data** includes billing address, email address, and telephone numbers.
- **Financial Data** includes payment card or other payment details. We store only limited, if any, Financial Data. Otherwise, our payment processor stores all Financial Data, and we encourage you to review their privacy policy and contact them directly for responses to your questions.
- **Transaction Data** includes details about payments from you and other details of services you have purchased through our Website.
- **Technical Data** includes internet protocol (IP) address, ISP name, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our Website.
- **Device Data** includes the type of mobile device (" **Device**") you use, a unique device identifier (for example, your Device's IMEI number, the MAC address of the Device's wireless network interface, or the mobile phone number used by the Device), mobile network information, your mobile operating system, the type of mobile browser you use, time zone setting, and IP address.
- **Content Data** includes photos, videos, audio (for example, music and other sounds), data, text (including comments and hashtags), text messages, metadata, images, interactive features, emojis, GIFs, memes, and any other material you post on or through our Website and information about the content you post. Please remember that Content Data that you post might reveal personal information about yourself, including identifying information about yourself depicted in any Content Data.
- **Profile Data** includes your username and password, including password hints and other information for authentication and account access, purchases made by you, your interests, preferences, feedback, and survey responses. Some information you choose to give us may be considered "special" or "sensitive" in certain jurisdictions, for example, your sex life and sexual orientation. **By choosing to submit special or sensitive information, you hereby consent to our processing of that information.**
- **Usage Data** includes information about how you use our Website, products, and services.
- **Marketing and Communications Data** includes your preferences in receiving marketing from us and your communication preferences.

We also collect, use, and share **Aggregated Data** including statistical or demographic data for any purpose. Aggregated Data could be derived from your personal information but is not considered personal information in law as this information will **not** directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal information so that it can directly or indirectly identify you, we treat the combined data as personal information that will be used under this Privacy Policy.

We do not collect any **Special Categories of Personal Data** about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data) unless you voluntarily provide it. Nor do we collect any information about criminal convictions and offenses.

Where we need to collect personal information by law, or under the terms of a contract we have with you and you do not provide that information when requested, we might not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us, but we will notify you if this is the case at the time.

We collect this information:

- Directly from you when you provide it to us.
- Automatically as you navigate through the Website. Information collected automatically might include Technical and Usage Data and information collected through cookies, web beacons, and other tracking technologies.
- From nonparties, for example, our business partners.

_ **Information You Provide to Us** _

The information we collect on or through our Website may include:

- Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you report a problem with our Website.
- Records and copies of your correspondence (including email addresses), if you contact us.
- Your responses to surveys that we might ask you to complete for research purposes.
- Details of transactions you carry out through our Website and of the fulfillment of your orders. You might be required to provide Financial Data before placing an order through our Website.
- Your search queries on the Website.

You also may provide Content Data to be published or displayed (" **posted**") on public areas of the Website or transmitted to other users of the Website or nonparties (collectively, " **User Contributions**"). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.

_ **Information We Collect Through Automatic Data Collection Technologies** _

As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including Technical and Usage Data.

Please note that we do not recognize or respond to any do not track signals (DNT). For more information about DNT, visit [www.allaboutdnt.com](http://www.allaboutdnt.com/).

The information we collect automatically is only statistical data and does not include personal information. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:

- Estimate our audience size and usage patterns.
- Store information about your preferences, allowing us to customize our Website according to your individual interests.
- Speed up your searches.
- Recognize you when you return to our Website.

The technologies we use for this automatic data collection might include:

- **Cookies (or browser cookies).** A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you might be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
- **Web Beacons.** Pages of our Website and our emails might contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).

**How We Use Your Information**

We use information that we collect about you or that you provide to us, including any personal information:

- To present our Website and its contents to you.
- To provide you with information, products, or services that you request from us.
- To fulfill any other purpose for which you provide it.
- To provide you with notices about your subscription, including expiration and renewal notices.
- To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
- To notify you about changes to our Website or any products or services we offer or provide through it.
- To allow you to participate in interactive features on our Website.
- To verify your identity and age when you sign up for our service.
- To monitor compliance with the Website's legal documents or for quality assurance purposes.
- To comply with law or credit card association standards.
- To assist law enforcement and respond to subpoenas.
- To deliver emails to users (i.e. potential subscribers, fans)
- To deliver newsletters and other information.
- To administer and protect our business and this Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data).
- To use data analytics to improve our website, products/services, marketing, customer relationships, and experiences.
- To detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, and prosecute those responsible for that activity.
- In any other way we may describe when you provide the information.
- For any other purpose with your consent.

**Disclosure of Your Information**

We may disclose Aggregated Data about our users, and information that does not identify any individual, without restriction.

We may disclose personal information that we collect or you provide as described in this Privacy Policy:

- To our subsidiaries and affiliates.
- To contractors, service providers, and other nonparties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
- To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Innumerable LLC's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Innumerable LLC about our Website users is among the assets transferred.
- To fulfill the purpose for which you provide it.
- For any other purpose disclosed by us when you provide the information.
- With your consent.

We may also disclose your personal information:

- To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
- To enforce or apply our [Terms of Use](https://couldbefans.com/terms-of-use) and other agreements, including for billing and collection purposes.
- If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of InnumerableLLC, our customers, or others. This includes exchanging information with other companies and organizations for cybersecurity, fraud protection, and credit risk reduction purposes.

The categories of personal information we may disclose include:

- Identity Data;
- Contact Data;
- Financial Data;
- Transactional Data;
- Technical Data;
- Device Data;
- Content Data; and
- Profile Data.

**Choices About How We Use and Disclose Your Information**

We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:

- **Tracking Technologies and Advertising**. You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of our Website might become inaccessible or not function properly. You can opt-out of Google Analytics here: [https://tools.google.com/dlpage/gaoptout](https://tools.google.com/dlpage/gaoptout).
- **Promotional Offers from the Company**. If you do not wish to have your email address used by the Company to promote our own or nonparties' products or services, you can opt-out by checking the relevant box located on the form on which we collect your data (the registration form) or at any other time by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to [privacy@couldbefans.com](mailto:privacy@couldbefans.com). If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.

Residents of certain jurisdictions, including California, Nevada, Colorado, Connecticut, Virginia, Utah, European Economic Area, and United Kingdom might have additional personal information rights and choices. Please see [Your State Privacy Rights](#bookmark=id.tyjcwt) and [Your EEA/UK Privacy Rights](#bookmark=id.3dy6vkm) for more information.

**Accessing and Correcting Your Information**

You can review and change your personal information by logging into the Website and visiting your account profile page.

You may also send us an email at [privacy@couldbefans.com](mailto:privacy@couldbefans.com) to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.

If you delete your User Contributions from the Website, copies of your User Contributions might remain viewable in cached and archived pages or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our Terms of Use[Terms of Use](https://couldbefans.com/terms-of-use).

Residents of certain jurisdictions, including California, Nevada, Colorado, Connecticut, Virginia, Utah, European Economic Area, and United Kingdom might have additional personal information rights and choices. Please see [Your State Privacy Rights](#bookmark=id.tyjcwt) and [Your EEA/UK Privacy Rights](#bookmark=id.3dy6vkm) for more information.

**Your State Privacy Rights**

State consumer privacy laws might provide their residents with additional rights regarding our use of their personal information. To learn more about California residents' privacy rights, visit our [CCPA Policy](https://couldbefans.com/ccpa-policy). California's "Shine the Light" law (Civil Code § 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to nonparties for their direct marketing purposes. To make that request, please send an email to [privacy@couldbefans.com](mailto:privacy@couldbefans.com).

Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:

- Confirm whether we process their personal information.
- Access and delete certain personal information.
- Data portability.
- Opt-out of personal data processing for targeted advertising and sales.

Colorado, Connecticut, and Virginia also provide their state residents with rights to:

- Correct inaccuracies in their personal information, taking into account the information's nature and processing purpose.
- Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.

To exercise any of these rights, please send an email to [privacy@couldbefans.com](mailto:privacy@couldbefans.com). To appeal a decision regarding a consumer rights request, please contact us at [privacy@couldbefans.com](mailto:privacy@couldbefans.com).

Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents who wish to exercise this sale opt-out rights may submit a request to this designated address:[privacy@couldbefans.com](mailto:privacy@couldbefans.com). However, please know we do not currently sell data triggering that statute's opt-out requirements.

**Your EEA/UK Privacy Rights**

If you live in the EEA or the UK, the following terms apply to you.

_ **Legal Basis for Processing** _

We may process your personal data because you have given us permission to do so (for example, by sending data through our Website's contact or signup forms), because the processing is in our legitimate interests and it is not overridden by your rights, or because we need to process your personal data to perform a contract with you or comply with the law.

_ **Your Legal Rights** _

Under certain circumstances, you have rights under data protection laws concerning your personal data. Your rights may include the following:

- **Request access** to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
- **Request correction** of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
- **Request erasure** of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully, or where we are required to erase your personal data to comply with local law. Note, however, that we might not always be able to comply with your request of erasure for specific legal reasons that will be notified to you, if applicable, at the time of your request.
- **Object to processing** of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation that makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information that override your rights and freedoms.
- **Request restriction of processing** your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (1) if you want us to establish the data's accuracy; (2) where our use of the data is unlawful, but you do not want us to erase it; (3) where you need us to hold the data even if we no longer require it as you need it to establish, exercise, or defend legal claims; or (4) you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
- **Request the transfer** of your personal data to you or a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information that you initially provided consent for us to use or where we used the information to perform a contract with you.
- **Withdraw consent at any time** where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we might not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.

If you wish to exercise any of the rights set out above, please contact us at [privacy@couldbefans.com](mailto:privacy@couldbefans.com).

You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to honor your request in these circumstances.

We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.

We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will let you know and keep you updated.

**Data Security**

We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.

The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website. The information you share in public areas may be viewed by any user of the Website.

Unfortunately, the transmission of information through the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.

**Changes to Our Privacy Policy**

Any changes we make to our Privacy Policy will be posted on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this Privacy Policy to check for any changes.

**Contact Information**

To ask questions or comment about this Privacy Policy and our privacy practices, contact us at [privacy@couldbefans.com](mailto:privacy@couldbefans.com).
`
