import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const isDashboard = location.pathname.includes('/dashboard/');

  return (
    <div
      className={`w-full flex flex-col md:flex-row items-start md:justify-evenly gap-6 p-10 ${
        isDashboard && 'lg:pl-[300px]'
      }`}
    >
      <div className="flex flex-col gap-2">
        <div className="text-gray-500 font-semibold text-xs">CouldBeFans</div>
        <a className="text-gray-600 font-semibold text-sm" href="/">
          About
        </a>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="https://getwaitlist.com/waitlist/6649"
          target="_blank"
        >
          Sign Up
        </a>
        <a className="text-gray-600 font-semibold text-sm" href="/unsubscribe">
          Unsubscribe
        </a>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-gray-500 font-semibold text-xs">Terms</div>
        <a className="text-gray-600 font-semibold text-sm" href="/terms-of-use">
          Terms of Use
        </a>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="/terms-of-service"
        >
          Terms of Service
        </a>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="/privacy-policy"
        >
          Privacy Policy
        </a>
        <a className="text-gray-600 font-semibold text-sm" href="/ccpa-policy">
          CCPA Policy
        </a>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-gray-500 font-semibold text-xs">Legal</div>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="/statement-2257"
        >
          18 U.S.C. 2257
        </a>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="/complaint-policy"
        >
          Complaints
        </a>
        <a className="text-gray-600 font-semibold text-sm" href="/dmca-policy">
          DMCA Policy
        </a>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-gray-500 font-semibold text-xs">Social</div>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="https://twitter.com/couldbefans"
        >
          Twitter
        </a>
        <a
          className="text-gray-600 font-semibold text-sm"
          href="https://instagram.com/couldbefans"
        >
          Instagram
        </a>
      </div>
    </div>
  );
};

export default Footer;
