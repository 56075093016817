export default `
**Terms-of-Use Agreement**

**Last Modified: 3/9/23**

**Acceptance of the Terms of Use**

These Terms of Use are entered into between you and Innumerable LLC (" **Innumerable** ," " **we** ," or " **us**"). The following terms govern your access to and use of [couldbefans.com](http://www.couldbefans.com/), including any content, functionality, and services offered on or through [couldbefans.com](http://www.couldbefans.com/) or its subdomains (" **Website**"), whether as a guest or a registered user.

Please read the Terms of Use carefully before you start to use the Website. **By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use.** If you do not want to agree to these Terms of Use, you must not access or use the Website.

This Website is offered and available to users who are at least 18-years old, who have reached the age of majority where they live, who reside in jurisdictions that do not prohibit websites with adult-oriented material, and who do not otherwise find adult-oriented material offensive. We forbid all persons who do not meet the age requirements from accessing the Website. By using this Website, you state that you are of legal age to form a binding contract with Innumerable and meet all the preceding eligibility requirements. If you do not meet all these requirements, you must not access or use the Website. **If minors have access to your computer, please restrain their access to adult-oriented material by using any of the following products, which we provide for informational purposes only:** [CYBERsitter™](http://www.cybersitter.com/) | [Net Nanny®](http://www.netnanny.com/) | [CyberPatrol](http://www.cyberpatrol.com/) | [ASACP](http://www.asacp.org/).

**Changes to the Terms of Use**

We may revise and update these Terms of Use from time to time. All changes are effective immediately when we post them and apply to all access to and use of the Website afterwards. But any changes to the dispute resolution provisions set out in [Governing Law and Jurisdiction](#bookmark=id.1fob9te) will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website.

Your continued use of the Website after the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.

**Accessing the Website and Account Security**

We may withdraw or amend this Website and any service or material we provide on the Website without notice. We will not be liable if for any reason any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.

You are responsible for both:

- Making all arrangements necessary for you to have access to the Website.
- Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.

To access the Website or some of the resources it offers, you might be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is accurate. All information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our [Privacy Policy](https://couldbefans.com/privacy-policy), and you consent to all actions we take regarding your information consistent with our Privacy Policy.

If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat that information as confidential, and you must not disclose it to any other person or entity. Your account is personal to you and you must not provide any other person with access to this Website or parts of it using your username, password, or other security information. You must notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also must ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.

We may disable any username, password, or other identifier, whether chosen by you or provided by us, for any reason, including if, in our opinion, you have violated any part of these Terms of Use.

**Intellectual Property Rights**

The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement of it), are owned by Innumerable, its licensors, or other providers of that material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

These Terms of Use allow you to use the Website for your personal, noncommercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:

- Your computer may temporarily store copies of those materials in RAM incidental to your accessing and viewing those materials.
- You may store files that are automatically cached by your Web browser for display enhancement purposes.
- You may print or download one copy of a reasonable number of pages of the Website for your own personal, noncommercial use and not for further reproduction, publication, or distribution.
- If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, noncommercial use, on condition that you agree to be bound by our end user license agreement for those applications.
- If we provide [social media features](#bookmark=id.30j0zll) with certain content, you may take those actions as are enabled by those features.

You must not:

- Modify copies of any materials from this Website.
- Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.
- Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this Website.

You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.

If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No interest in the Website or any content on the Website is transferred to you, and Innumerable reserves all rights not granted. Any use of the Website not permitted by these Terms of Use is a breach of these Terms of Use and might violate copyright, trademark, and other laws.

**Trademarks**

The Innumerable name; the term CouldBeFans; the CouldBeFans logo; and all related names, logos, product and service names, domain names, designs, and slogans are trademarks of Innumerable or its affiliates or licensors. You must not use those marks without Innumerable's prior written permission. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.

**Prohibited Uses**

You may use the Website only for lawful purposes and in accordance with these Terms of Use. You must not use the Website:

- In any way that violates any federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
- To exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
- To send, knowingly receive, upload, download, use, or reuse any material that does not comply with the [Content Standards](#bookmark=id.gjdgxs) set out in these Terms of Use.
- To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
- To impersonate or attempt to impersonate Innumerable, a Innumerable employee, another user, or any other person or entity (including, without limitation, by using email addresses or usernames associated with any of the preceding).
- To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or that, as determined by us, might harm Innumerable or users of the Website, or expose them to liability.

Additionally, you must not:

- Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website.
- Conduct, facilitate, authorize, or permit any text or data mining or web scraping regarding the Website or any services provided through, or concerning, the Website. This includes using (or permitting, authorizing, or attempting to use): (1) any "robot," "bot," "spider," "scraper," or other automated device, program, tool, algorithm, code, process, or methodology to access, obtain, copy, monitor, record, or republish any part of the Website or any data, content, information, or services accessed through the Website; or (2) any automated analytical technique aimed at analyzing text and data in digital form to generate information that includes but is not limited to patterns, trends, and correlations.
- Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.
- Use any device, software, or routine that interferes with the Website's proper working.
- Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
- Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.
- Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
- Otherwise try to interfere with the Website's proper working.

**User Contributions**

The Website contains interactive features (" **Interactive Services**")that allow users to post, submit, publish, display, or transmit to other users or other persons (" **Post**") content or materials (collectively, " **User Contributions**") on or through the Website.

All User Contributions must comply with the [Content Standards](#bookmark=id.gjdgxs) set out in these Terms of Use.

Any User Contribution you post to the Website will be considered nonconfidential and nonproprietary. By providing any User Contribution on the Website, you hereby grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns a license to use, reproduce, modify, perform, display, distribute, and otherwise disclose to nonparties any User Contribution for any purpose.

You state that the following facts are accurate:

- You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.
- All your User Contributions do and will comply with these Terms of Use.

You are responsible for any User Contributions you submit or contribute, and you, not Innumerable, have full responsibility for that content, including its legality, reliability, accuracy, and appropriateness.

We are not responsible or liable to any nonparty for the content or accuracy of any User Contributions you or any other Website user posts.

**Monitoring and Enforcement; Termination**

We may:

- Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
- Take any action regarding any User Contribution that we consider necessary or appropriate in our sole discretion, including if we believe that the User Contribution violates the Terms of Use, including the [Content Standards](#bookmark=id.gjdgxs), infringes any intellectual property right or other right of any person or entity, threatens the personal safety of the Website's users or the public, or could create liability for Innumerable.
- Disclose your identity or other information about you to any nonparty who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.
- Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website, including posting illegal or unauthorized User Contribution (Innumerable will report any User Contribution that could be considered child sexual abuse material (CSAM) to the National Center for Missing and Exploited Children's CyberTipline and any other legal and regulatory bodies).
- Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.

Without limiting the preceding, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. **You hereby waive and shall indemnify Innumerable and its affiliates, licensees, and service providers against any claims resulting from any action taken by any of the preceding parties during, or taken because of, investigations by either those parties or law enforcement authorities.**

We review all material before it is posted on the Website to ensure that it is not illegal and does not otherwise violate the Terms of Use. If we determine that any User Contribution is illegal or otherwise violates the Terms of Use, we will promptly block and remove that User Contribution. However, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or nonparty. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.

**Content Standards**

These content standards apply to all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all federal, state, local, and international laws and regulations. Without limiting the preceding, User Contributions must not:

- Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
- Contain children, child sexual abuse material, age-play, incest, rape or non-consensual sex, hypnosis, intoxication, sexual assault, extreme violence, non-consensual pain, blood, cutting, erotic asphyxiation, torture, necrophilia, sadomasochistic abuse, hardcore bondage, extreme fisting, genital mutilation, bestiality, urine or water sports, scat or excrement-related material, enema play, vomiting, menstrual bleeding, or any other matter that is illegal.
- Contain unsolicited content or unsolicited language that sexually objectifies another person in a non-consensual way or contains fake or manipulated content concerning another person (including "deepfakes").
- Promote, depict, or constitute "revenge porn" (being any sexually explicit material featuring any individual who has not given prior, express, and informed consent to that material (1) being taken, captured, or otherwise memorialized; or (2) being posted and shared on the Website).
- Promote violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
- Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
- Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise might be in conflict with these Terms of Use and our [Privacy Policy](https://couldbefans.com/privacy-policy).
- Be likely to deceive any person.
- Promote any illegal activity, or advocate, promote, or assist any unlawful act, including, but not limited to, prostitution, sex trafficking, or human trafficking.
- Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.
- Impersonate any person, or misrepresent your identity or affiliation with any person or organization.
- Involve commercial activities or sales, including contests, sweepstakes, and other sales promotions, barter, or advertising.
- Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.

**Copyright Infringement**

If you believe that any User Contributions violate your copyright, please see our [DMCA Policy](https://couldbefans.com/dmca-policy) for instructions on sending us a notice of copyright infringement. Innumerable's policy is to terminate repeat infringers' user accounts.

**Reliance on Information Posted**

The information presented on or through the Website is made available solely for general information purposes. We are not making any warranty about the accuracy, completeness, or usefulness of this information. Any reliance you place on that information is strictly at your own risk. We will not be liable for any reliance placed on those materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.

This Website might include content provided by nonparties, including materials provided by other users, bloggers, and nonparty licensors, syndicators, aggregators, or reporting services. All statements or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Innumerable, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect Innumerable's opinion. We are not responsible, or liable to you or any nonparty, for the content or accuracy of any materials provided by any nonparties.

**Changes to the Website**

We may update the content on this Website from time to time, but its content is not necessarily complete or up to date. Any of the material on the Website might be out of date at any given time, and we are not required to update that material.

**Information About You and Your Visits to the Website**

All information we collect on this Website is subject to our [Privacy Policy](https://couldbefans.com/privacy-policy). By using the Website, you hereby consent to all actions taken by us regarding your information in compliance with the Privacy Policy.

**Online Purchases and Other Terms**

All purchases through our Website or other transactions for the sale of services formed through the Website, or resulting from visits made by you, are governed by our Terms of Service.

Additional terms might also apply to specific portions, services, or features of the Website.

**Linking to the Website and Social Media Features**

You may link to our homepage, on condition that you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our written consent.

This Website may provide certain social media features that enable you to:

- Link from your own or certain nonparty websites to certain content on this Website.
- Send emails or other communications with certain content, or links to certain content, on this Website.
- Cause limited parts of content on this Website to be displayed or appear to be displayed on your own or certain nonparty websites.

You may use these features solely as they are provided by us, solely regarding the content they are displayed with, and otherwise in accordance with any additional terms we provide regarding those features. Subject to the preceding, you must not:

- Establish a link from any website that is not owned by you.
- Cause the Website or parts of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.
- Link to any part of the Website other than the homepage.
- Otherwise take any action regarding the materials on this Website that is inconsistent with any other provision of these Terms of Use.

The website from which you are linking, or on which you make certain content accessible, must comply with the [Content Standards](#bookmark=id.gjdgxs) set out in these Terms of Use.

You must cooperate with us in causing any unauthorized framing or linking immediately to stop. We may withdraw linking permission without notice.

We may disable all or any social media features and any links at any time without notice.

**Links from the Website**

If the Website contains links to other sites and resources provided by nonparties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that might arise from your use of them. If you decide to access any of the nonparty websites linked to this Website, you do so entirely at your own risk and subject to the terms for those websites.

**Geographic Restrictions**

The Website's owner is based in Delaware in the United States. We provide this Website for use only by persons located in the United States and those countries where the distribution of sexually explicit content is legal. We make no claims that the Website or any of its content is accessible or appropriate outside the United States. Access to the Website might not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.

**Warranty Disclaimers**

You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Website for any reconstruction of any lost data. **To the extent provided by law, we will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that might infect your computer equipment, computer programs, data, or other proprietary material due to your use of the Website or any services or items obtained through the Website or to your downloading of any material posted on it, or on any website linked to it.**

**Your use of the Website, its content, and any services or items obtained through the Website is at your own risk. The Website, its content, and any services or items obtained through the Website are provided "as is" and "as available," without any warranties, either express or implied. Neither Innumerable nor any person associated with Innumerable is making any warranty or statement regarding the completeness, security, reliability, quality, accuracy, or availability of the Website. Without limiting the preceding, neither Innumerable nor anyone associated with Innumerable states that the Website, its content, or any services or items obtained through the Website will be accurate, reliable, error-free, or uninterrupted; that defects will be corrected; that our Website or the server that makes it available are free of viruses or other harmful components; or that the Website or any services or items obtained through the Website will otherwise meet your needs or expectations.**

**To the extent provided by law, Innumerable is not making any warranties, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, noninfringement, and fitness for particular purpose.**

**The preceding does not affect any warranties that cannot be excluded or limited under law.**

**Limitation on Liability**

**To the extent provided by law, in no event will Innumerable, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the Website, any websites linked to it, any content on the Website or those other websites, including any direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.**

**The preceding does not affect any liability that cannot be excluded or limited under law.**

**Indemnification**

You shall indemnify Innumerable, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.

**Governing Law and Jurisdiction**

Delaware law governs all adversarial proceedings arising out of this agreement or use of the Website.

As the exclusive means of bringing adversarial proceedings to resolve any dispute arising out of the Terms of Use or the subject matter of the Terms of Use, a party may bring such a proceeding in the United States District Court for the District of Delaware or in a state court of Delaware. Each party acknowledges that those courts would be a convenient forum.

**Jury Trial Waiver**

**Each party hereby waives its right to a trial by jury in any adversarial proceedings arising out of the Terms of Use.**

**Arbitration**

Innumerable may require you to submit any disputes arising from these Terms of Use or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, nonperformance, or termination, to final and binding arbitration under the arbitration rules of Arbitration Resolution Services, Inc. (ARS) (or a similar online dispute resolution provider if ARS is not available). The ARS rules are available at [www.arbresolutions.com](http://www.arbresolutions.com/).

**Limitation on Time to File Claims**

**Any cause of action or claim you may have arising out of or relating to these Terms of Use or the Website must be filed within one (1) year after the cause of action accrues; otherwise, that cause of action or claim is permanently barred.**

**Waiver and Severability**

No waiver under these Terms of Use will be effective unless it is in writing and signed by the party granting the waiver. A waiver granted on one occasion will not operate as a waiver on other occasions.

If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, that provision will be eliminated or limited to the minimum extent so that the remaining provisions of the Terms of Use will continue in full effect.

**California Users and Residents**

If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.

**Entire Agreement**

These Terms of Use constitute the entire understanding between the parties regarding the subject matter of these Terms of Use and supersede all other agreements, whether written or oral, between the parties, except that the Terms of Use do not supersede the Terms of Service.

**Your Comments and Concerns**

This Website is operated by Innumerable LLC, 1200 Fitzgerald Drive #1140 Pinole, CA 94564.

All notices of copyright infringement claims should be sent to the copyright agent designated in our [DMCA Policy](https://couldbefans.com/dmca-policy) in the manner and by the means set out in it.

All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: [help@couldbefans.com](mailto:help@couldbefans.com).
`;
