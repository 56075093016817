import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadMedia } from '../../api/media';
import Image from '../../assets/icons/image-icon.svg';
import Button from '../../components/util/Button';
import { setLoading } from '../../state/uiSlice';
import InactiveStep from './InactiveStep';
import { updateUser } from '../../api/profile';
import { setProfile } from '../../state/userSlice';
import MediaAgreement from '../../components/util/MediaAgreement';

const SetupProfilePicture = ({ title, number }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [fileError, setFileError] = useState(null);
  const [mediaAgreement, setMediaAgreement] = useState(false);

  const uploadProfilePicture = async () => {
    dispatch(setLoading(true));
    try {
      const medias = await uploadMedia([file]);
      setFile(null);
      const response = await updateUser({
        media_id: medias[0].id,
      });
      dispatch(setProfile(response.data.profile));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <InactiveStep title={title} number={number} completed={false} />

      {file && (
        <div>
          <div className="font-semibold text-[18px] text-gray-900">
            Pending Review
          </div>
          <div className="text-[14px] text-gray-600">
            This media won't be available until it's reviewed and approved by
            CouldBeFans.
          </div>
          <img
            src={URL.createObjectURL(file)}
            className="object-cover w-[150px] h-[150px] rounded-full mt-4"
          />
        </div>
      )}
      <div
        className="w-full flex gap-2 rounded-md border-gray-300 border-[1px] p-2 mt-6 cursor-pointer flex justify-center"
        onClick={() => {
          inputFile.current.click();
        }}
      >
        <img src={Image} />
        <p className="font-semibold text-sm text-gray-700">
          Select profile picture
        </p>
      </div>
      {file && (
        <>
          <MediaAgreement setCompletedAgreement={setMediaAgreement} />
          <Button
            type="action"
            disabled={!mediaAgreement}
            title="Continue"
            onClick={() => {
              uploadProfilePicture();
            }}
            className="w-full mt-2"
          />
        </>
      )}
      {fileError && <div className="text-red-500">{fileError}</div>}

      <input
        type="file"
        id="file"
        accept=".jpeg,.jpg,.png,.gif"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (event.target.files[0].size > 1e8) {
            return setFileError('File cannot exceed 100MB');
          } else if (!event.target.files[0].type.includes('image/')) {
            return setFileError('File must be image or GIF');
          }
          setFile(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default SetupProfilePicture;
