import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/icons/logo.svg';
import Button from '../components/util/Button';
import { resetPassword } from '../api/auth';

function PasswordReset() {
  const params = useParams();
  const resetId = params.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const submit = async () => {
    if (loading) return;
    if (newPassword != confirmPassword) {
      return setError('Passwords do not match');
    } else if (newPassword.length < 8) {
      return setError('Password must be at least 8 characters long');
    }
    setError(null);
    dispatch(setLoading(true));
    try {
      const response = await resetPassword(resetId, newPassword);
      setSubmitted(true);
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col items-center">
      <img src={Logo} className="w-[48px] mt-20" />
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">
        Reset Password
      </h2>
      <div className="mt-2 text-gray-500 text-center">
        Choose a new password
      </div>
      {!submitted ? (
        <>
          <div className="mt-8 w-full">
            <label className="text-gray-700">Password</label>
            <input
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Password"
              className="block w-full mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
              value={newPassword}
            />
          </div>
          <div className="mt-8 w-full">
            <label className="text-gray-700">Confirm Password</label>
            <input
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              className="block w-full mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
              value={confirmPassword}
            />
          </div>
          <Button
            type="action"
            onClick={() => {
              submit();
            }}
            className="w-full mt-6"
            disabled={false}
            title="Reset Password"
          />
          {error && <div className="text-red-500">{error}</div>}
        </>
      ) : (
        <div className="p-2 mt-8 border-[1px] border-gray-100 drop-shadow-md bg-white rounded-lg">
          <div className="">Your password has been reset!</div>
          <a href="/dashboard/login" className="text-cbf-primary font-semibold">
            Back to login
          </a>
        </div>
      )}
    </main>
  );
}

export default PasswordReset;
