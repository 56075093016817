import React, { useEffect, useState } from 'react';
import MediaSelection from './MediaSelection';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { setMediaItems } from '../state/mediaSlice';
import EmptyMedia from './EmptyMedia';
import { getMedia } from '../api/media';

const MediaLibrary = ({ onSelection }) => {
  const dispatch = useDispatch();
  const mediaItems = useSelector((state) => state.media.items);

  useEffect(() => {
    if (mediaItems) return;
    (async () => {
      dispatch(setLoading(true));
      console.log('getting media');
      try {
        const response = await getMedia();
        dispatch(setMediaItems(response.data));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, []);

  if (mediaItems?.filter((item) => item.status == 'LIVE').length == 0) {
    return (
      <div className="h-[400px] w-full flex flex-col items-center justify-center">
        <div className="text-center">
          You don't have any verified media. Go to the{' '}
          <span>
            <a href="/dashboard/media" className="text-cbf-primary underline">
              Media
            </a>
          </span>{' '}
          page to upload.
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 md:grid-cols-3 gap-[1px] w-full max-h-[400px] overflow-y-scroll">
      {mediaItems
        ?.filter((item) => item.status == 'LIVE')
        .map((item) => {
          return (
            <div
              className="w-full shadow-lg aspect-square flex justify-center items-center overflow-hidden cursor-pointer"
              key={item.id}
            >
              <MediaSelection
                key={item.id}
                item={item}
                onSelection={() => {
                  onSelection(item);
                }}
              />
            </div>
          );
        })}
      {Array(
        Math.max(
          0,
          mediaItems
            ? 9 - mediaItems?.filter((item) => item.status == 'LIVE').length
            : 9
        )
      )
        .fill(0)
        .map((item, index) => {
          return (
            <div
              className="w-full shadow-lg aspect-square flex justify-center items-center overflow-hidden cursor-pointer"
              key={index}
            >
              <EmptyMedia key={index} />
            </div>
          );
        })}
    </div>
  );
};

export default MediaLibrary;
