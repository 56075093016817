import React from 'react';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';

const LoadingOverlay = () => {
  const loading = useSelector((state) => state.ui.loading);

  if (!loading) return null;

  return (
    <div
      className={`fixed top-0 bottom-0 right-0 left-0 ${
        loading == 'OPAQUE' ? 'bg-white' : 'bg-white/80'
      } flex justify-center items-center`}
    >
      <TailSpin
        height="80"
        width="80"
        color="#7F56D9"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default LoadingOverlay;
