import axios from 'axios';
import { apiUrlForPath } from './api';

export const getAnalytics = async () => {
  return axios.get(apiUrlForPath('analytics'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};
