import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../state/uiSlice';
import { adminGetCreators, adminPutCreatorVerified } from '../../api/admin';
import Checkbox from '../../components/util/Checkbox';
import Toggle from '../../components/util/Toggle';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Email',
    selector: (creator, index) => {
      return creator.email;
    },
  },
  {
    name: 'OnlyFans',
    selector: (creator, index) => {
      return creator.only_fans;
    },
  },
  {
    name: 'Campaign Live',
    selector: (creator, index) => {
      return creator.campaign_live ? (
        <Checkbox disabled={true} value={creator.campaign_live} />
      ) : null;
    },
  },
  {
    name: 'ID Verified',
    selector: (creator, index) => {
      return (
        <Toggle
          value={creator.verified}
          onToggle={() => {
            creator.onToggleVerify();
          }}
        />
      );
    },
  },
];

const AdminCreators = () => {
  const dispatch = useDispatch();

  const [creators, setCreators] = useState([]);

  const loadCreators = async () => {
    dispatch(setLoading(true));
    try {
      const response = await adminGetCreators();
      setCreators(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const verifyCreator = async (creatorId, verified) => {
    dispatch(setLoading(true));
    try {
      console.log(creatorId);
      const response = await adminPutCreatorVerified(creatorId, verified);
      await loadCreators();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    loadCreators();
  }, []);

  return (
    <div className="w-full">
      <DataTable
        title="Creators"
        columns={columns}
        data={creators.map((creator) => {
          const creatorId = creator.id;
          const verified = creator.verified;
          return {
            ...creator,
            onToggleVerify: () => {
              verifyCreator(creatorId, !verified);
            },
          };
        })}
      />
    </div>
  );
};

export default AdminCreators;
