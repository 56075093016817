import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verify } from '../api/subscribe';
import UserHeader from '../components/UserHeader';
import { setLoading } from '../state/uiSlice';
import ReactGA from 'react-ga4';
import Logo from '../assets/icons/logo.svg';

const SubscribeVerify = ({}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const loading = useSelector((state) => state.ui.loading);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const id = params.id;
    (async () => {
      dispatch(setLoading(true));
      try {
        const response = await verify(id);
        ReactGA.event({
          category: 'conversion',
          action: 'subscribe_verify',
          label: response.data.only_fans,
        });
        setProfile(response.data);
      } catch (e) {
        console.log(e);
        if (e.response.status == 400) {
          setError('Subscription is already verified');
        } else {
          setError('Subscription does not exist');
        }
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [params]);

  if (error) {
    return (
      <div className="flex flex-col items-center mb-20">
        <img src={Logo} className="w-[48px] mt-20" />
        {error}
      </div>
    );
  }

  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col">
      <UserHeader profile={profile} />
      <div className="p-4">
        <div className="font-semibold mt-10">Verified!</div>
        <div className="text-gray-700">
          Your first piece of content is on its way. Don't forget to move
          CouldBeFans.com from your spam filters.
        </div>
      </div>
    </main>
  );
};

export default SubscribeVerify;
