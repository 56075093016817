import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMedia, uploadMedia } from '../api/media';
import { setMediaItems } from '../state/mediaSlice';
import { setLoading } from '../state/uiSlice';
import MediaView from './MediaView';
import Button from './util/Button';
import Checkbox from './util/Checkbox';
import MediaAgreement from './util/MediaAgreement';

const UploadMedia = ({ files, setFiles, fileError, setFileError }) => {
  const dispatch = useDispatch();

  const [mediaAgreement, setMediaAgreement] = useState(false);

  const upload = async () => {
    if (files.length > 0) dispatch(setLoading(true));
    try {
      await uploadMedia(files);
      setFiles(null);
      const response = await getMedia();
      dispatch(setMediaItems(response.data));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="overflow-hidden flex flex-col h-full max-w-2xl">
      <p className="font-semibold text-[20px] text-gray-700 my-6">
        Image Upload
      </p>
      {fileError ? (
        <div className="flex items-center justify-center text-red-500">
          {fileError}
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-2">
          {Array.from(files).map((file) => {
            console.log(file);
            return (
              <MediaView
                mediaUrl={URL.createObjectURL(file)}
                mimeType={file.type}
                key={file.name}
              />
            );
          })}
        </div>
      )}
      <MediaAgreement setCompletedAgreement={setMediaAgreement} />
      <div className="flex justify-end mt-6 gap-2 mb-2 mr-2">
        <Button
          title="Cancel"
          onClick={() => {
            setFileError(null);
            setFiles(null);
          }}
          type="cancel"
        />
        <Button
          title="Upload"
          onClick={() => {
            upload();
          }}
          type="action"
          disabled={!!fileError || !mediaAgreement}
        />
      </div>
    </div>
  );
};

export default UploadMedia;
