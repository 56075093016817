import React from 'react';
import Copy from '../assets/icons/copy.svg';

const SubscribeLinkCopy = ({ username }) => {
  return (
    <div
      className={`flex items-center gap-2 ${username ? 'cursor-pointer' : ''}`}
      onClick={() => {
        if (!username) return;
        navigator.clipboard.writeText(
          `https://couldbefans.com/subscribe/${username}`
        );
      }}
    >
      <div className="cursor-pointer text-gray-600 text-sm">
        {`couldbefans.com/subscribe/${username || '...'}`}
      </div>
      {username && <img src={Copy} />}
    </div>
  );
};

export default SubscribeLinkCopy;
