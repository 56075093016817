import React from 'react';
import MediaLibrary from './MediaLibrary';
import Button from './util/Button';

const MediaManager = ({ onSelection, onCancel }) => {
  return (
    <div className="rounded-xl bg-white w-full relative border-[1px] border-gray-200 p-1 max-w-[500px] max-h-[600px]">
      <MediaLibrary
        onSelection={(item) => {
          onSelection(item);
        }}
      />
      <div className="flex justify-end mt-6 mb-2 mr-2">
        <div className="grow text-gray-600 text-sm">
          Don't see your media? Only verified media is available to add to a
          campaign. Add more on the{' '}
          <a href="/dashboard/media" className="text-cbf-primary underline">
            Media
          </a>{' '}
          page
        </div>
        <Button
          title="Cancel"
          onClick={() => {
            onCancel();
          }}
          type="cancel"
        />
      </div>
    </div>
  );
};

export default MediaManager;
