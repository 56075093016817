import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CampaignItem from '../components/CampaignItem';
import MediaLibrary from '../components/MediaLibrary';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import {
  getCampaignError,
  setCampaignItems,
  setEditingCampaignIndex,
} from '../state/campaignSlice';
import { useNavigate } from 'react-router-dom';
import EmptyCampaignItem from '../components/EmptyCampaignItem';
import EditCampaignItem from '../components/EditCampaignItem';
import CampaignHeader from '../components/CampaignHeader';
import Toggle from '../components/util/Toggle';
import {
  getCampaign,
  setCampaignStatus,
  setCampaignInterval,
} from '../api/campaign';
import GoLivePopup from '../components/GoLivePopup';

function Campaign() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const profile = useSelector((state) => state.user.profile);
  const editingCampaignIndex = useSelector(
    (state) => state.campaign.editingCampaignIndex
  );
  const [campaignLive, setCampaignLive] = useState(false);
  const [interval, setInterval] = useState(null);
  const [error, setError] = useState(null);
  const [showSubscribeUrl, setShowSubscribeUrl] = useState(false);

  useEffect(() => {
    if (!profile) return;
    setCampaignLive(profile.campaign_live);
    setInterval(profile.campaign_interval);
  }, [profile]);

  useEffect(() => {
    const loadCampaign = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getCampaign();
        dispatch(setCampaignItems(response.data));
      } catch (e) {
        console.log('error', e);
      } finally {
        dispatch(setLoading(false));
      }
    };
    loadCampaign();
  }, []);

  const toggleCampaign = async () => {
    dispatch(setLoading(true));
    const newValue = !campaignLive;
    try {
      const response = await setCampaignStatus(newValue);
      if (newValue) {
        setShowSubscribeUrl(true);
      }
      setCampaignLive(newValue);
    } catch (e) {
      console.log(e);
      setError(e.response.status);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateInterval = async (newInterval) => {
    dispatch(setLoading(true));
    try {
      const response = await setCampaignInterval(parseInt(newInterval));
      setInterval(parseInt(newInterval));
      console.log(response);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (!profile || !profile.only_fans) {
    return (
      <main className="main mb-10 w-full lg:max-w-2xl lg:py-4 pb-4 flex flex-col justify-center items-center">
        <CampaignHeader />
        <div className="text-red-500 max-w-md text-center mt-10">
          You must connect your OnlyFans on the Profile page before you can set
          up a campaign.
        </div>
      </main>
    );
  }

  return (
    <main className="main mb-10 w-full lg:max-w-2xl lg:py-4 pb-4">
      <CampaignHeader />

      <div className="flex flex-col gap-5 px-4">
        {profile && (
          <div className="border-[1px] border-gray-200 drop-shadow-md rounded-lg p-4  bg-white">
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <div className="text-gray-700 font-medium">
                  {campaignLive ? 'Campaign is active' : 'Campaign is inactive'}
                </div>
                <div className="text-gray-600 font-regular">
                  {campaignLive
                    ? 'Subscribers are currently receiving emails'
                    : profile.campaignValid
                    ? 'Your subscribers are not currently receiving emails'
                    : "You can go live once you've filled out all 5 days and your media has been verified."}
                </div>
              </div>
              <div>
                <Toggle
                  value={campaignLive}
                  onToggle={() => {
                    if (getCampaignError(campaign)) {
                      return;
                    }
                    toggleCampaign();
                  }}
                />
              </div>
            </div>
            <div className="flex mt-4 items-center gap-4">
              <div className="text-sm">
                <div className="text-gray-700 font-medium">
                  Campaign schedule
                </div>
                <div className="text-gray-600 font-regular">
                  This is the amount of time waited between each email sent out
                  in a campaign
                </div>
              </div>
              <select
                className="rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block mt-2"
                value={interval || ''}
                onChange={async (event) => {
                  console.log('sending', event.target.value);
                  const success = await updateInterval(event.target.value);
                }}
              >
                <option value={24 * 60 * 60 * 1000}>Daily</option>
                <option value={7 * 24 * 60 * 60 * 1000}>Weekly</option>
              </select>
            </div>
          </div>
        )}

        {campaign?.items.map((item, index) => {
          if (index === editingCampaignIndex) {
            return <EditCampaignItem index={index} item={item} key={index} />;
          } else if (Object.keys(item).length === 0) {
            return (
              <EmptyCampaignItem
                index={index}
                onSelect={() => {
                  dispatch(setEditingCampaignIndex(index));
                }}
                key={index}
              />
            );
          } else {
            return <CampaignItem index={index} key={index} />;
          }
        })}
      </div>
      {showSubscribeUrl && (
        <GoLivePopup
          profile={profile}
          onClose={() => {
            setShowSubscribeUrl(false);
          }}
        />
      )}
    </main>
  );
}

export default Campaign;
