import { createSlice } from '@reduxjs/toolkit';

export const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    items: null,
    selectionCallback: null,
    showMediaModal: false,
  },
  reducers: {
    setMediaItems: (state, action) => {
      state.items = action.payload;
    },
    setMediaSelectionCallback: (state, action) => {
      state.selectionCallback = action.payload;
    },
    setShowMediaModal: (state, action) => {
      state.showMediaModal = action.payload;
    },
  },
});

export const { setMediaItems, setMediaSelectionCallback, setShowMediaModal } =
  mediaSlice.actions;
export default mediaSlice.reducer;
