import React from 'react';
import { mediaUrl } from '../api/media';
import { getSkin } from '../assets/skins/index';
import ProfileMedia from './util/ProfileMedia';
const UserHeader = ({ centered = true, profile }) => {
  return (
    <div className="w-full flex flex-col relative">
      <div className="w-full h-full mb-[50px] lg:mb-[45px] lg:rounded-xl h-[125px] md:h-[160px] overflow-hidden">
        <img
          src={getSkin(profile?.skin)}
          className="w-full h-full object-cover"
        />
      </div>
      <div
        className={`absolute top-0 bottom-0 ${
          centered ? 'left-0 right-0' : 'left-2'
        } lg:left-0 lg:right-0 flex flex-col items-center justify-end`}
      >
        {profile ? (
          <div className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] border-4 border-white rounded-full drop-shadow-md bg-cbf-primary">
            <ProfileMedia media={profile.media} />
          </div>
        ) : (
          <div className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] border-4 border-white rounded-full drop-shadow-md bg-cbf-primary"></div>
        )}
      </div>
    </div>
  );
};

export default UserHeader;
