import React, { useState } from 'react';
import Hero from './Hero';
import Header from './Header';
import HowItWorks from './HowItWorks';
import WaitlistBold from './WaitlistBold';
import Features from './Features';
import WaitlistLight from './WaitlistLight';
import { validate } from 'react-email-validator';
import Problem from './Problem';
import { joinWaitlist } from '../../api/waitlist';
import AdTracker from './AdTracker';
import WhatWeDo from './WhatWeDo';

const Home = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const submitWaitlist = async () => {
    if (!validate(email)) {
      return setError('Invalid email address');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await joinWaitlist(email);
      console.log(response);
      setSubmitted(true);
    } catch (e) {
      console.log('ERROR', e);
      setError('Error joining waitlist');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-white">
      <AdTracker />
      <Header />
      <Hero
        email={email}
        setEmail={setEmail}
        loading={loading}
        error={error}
        submitted={submitted}
        onSubmit={() => {
          submitWaitlist();
        }}
      />
      <Problem />
      <WhatWeDo />
      <HowItWorks />
      <WaitlistBold />
      <Features />
      <WaitlistLight
        email={email}
        setEmail={setEmail}
        loading={loading}
        error={error}
        submitted={submitted}
        onSubmit={() => {
          submitWaitlist();
        }}
      />
    </div>
  );
};

export default Home;
