import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/icons/logo.svg';
import Button from '../components/util/Button';
import { validate } from 'react-email-validator';
import { forgotPassword } from '../api/auth';
import Email from '../assets/icons/email-pulse.svg';

function ForgotPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    if (loading) return;
    dispatch(setLoading(true));
    try {
      const response = await forgotPassword(email);
      setSubmitted(true);
      dispatch(setLoading(false));
    } catch (e) {
      setSubmitted(true);
      dispatch(setLoading(false));
    }
  };

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col items-center">
      <img src={Logo} className="w-[48px] mt-20" />
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">
        Forgot Password
      </h2>
      <div className="mt-2 text-gray-500 text-center">
        Enter the e-mail of your account to reset your password
      </div>
      {!submitted ? (
        <>
          <div className="mt-8 w-full">
            <label className="text-gray-700">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="block w-full mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
              value={email}
            />
          </div>
          <Button
            type="action"
            onClick={() => {
              submit();
            }}
            className="w-full mt-6"
            disabled={!validate(email)}
            title="Reset Password"
          />
        </>
      ) : (
        <div className="p-2 mt-8 border-[1px] border-gray-100 drop-shadow-md bg-white rounded-lg">
          <img src={Email} className="w-[40px]" />
          <div className="font-semibold text-gray-900">
            Password reset is on its way!
          </div>
          <div className="text-gray-600">
            Please check your email for further instructions.
          </div>
        </div>
      )}
    </main>
  );
}

export default ForgotPassword;
