import axios from 'axios';
import { apiUrlForPath } from './api';

export const getCampaign = async () => {
  return axios.get(apiUrlForPath('campaign'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const updateCampaignItem = async (item, index) => {
  return axios.post(
    apiUrlForPath('campaign'),
    { item, index },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const setCampaignStatus = async (value) => {
  return axios.post(
    apiUrlForPath('campaign/status'),
    { value },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const setCampaignInterval = async (interval) => {
  return axios.post(
    apiUrlForPath('campaign/interval'),
    { interval },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};
