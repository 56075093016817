import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../api/profile';
import Button from '../../components/util/Button';
import { setLoading } from '../../state/uiSlice';
import { setProfile } from '../../state/userSlice';
import InactiveStep from './InactiveStep';

const SetupOnlyFans = ({ title, number }) => {
  const dispatch = useDispatch();
  const [onlyFansLink, setOnlyFansLink] = useState('');
  const [onlyFansError, setOnlyFansError] = useState(null);

  const submit = async () => {
    dispatch(setLoading(true));
    try {
      const response = await updateUser({
        only_fans_link: onlyFansLink,
      });
      dispatch(setProfile(response.data.profile));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <InactiveStep title={title} number={number} completed={false} />
      <div className="text-gray-700 font-medium text-sm mt-8">
        OnlyFans Link
      </div>
      <div className="flex w-full">
        <div className="text-gray-900 p-3 grow border-[1px] border-gray-300 rounded-r-lg border-l-1 rounded-l-lg mt-2">
          <input
            className="w-full"
            placeholder="https://onlyfans.com/myusername/c1"
            value={onlyFansLink}
            onChange={(event) => {
              let url = event.target.value;
              url = url.replace('http://', '').replace('https://', '');
              url = url.replace('onlyfans.com/', '');
              const [username, tag] = url.split('/c');
              if (!username || !tag || isNaN(tag)) {
                setOnlyFansError(
                  'Not a valid tracking link. Make sure to copy it from the "Tracking Links" page in OnlyFans. It should end in something like "/c1" or "/c2"'
                );
              } else {
                setOnlyFansError(null);
              }
              setOnlyFansLink(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="text-red-500 text-xs mt-2">{onlyFansError}</div>
      <div className="text-gray-600 text-xs mt-2">
        To best track your campaign with accurate statistics, this should be a
        tracking link for OnlyFans. You can generate one by clicking here:{' '}
        <a
          href="https://onlyfans.com/my/settings/tracking-links"
          target="_blank"
          rel="noopener noreferrer"
          className="text-cbf-primary"
        >
          Create a tracking link
        </a>
      </div>
      <Button
        type="action"
        title="Save"
        onClick={() => {
          submit();
        }}
        disabled={onlyFansError || onlyFansLink.length < 10}
        className="w-full mt-6"
      />
    </div>
  );
};

export default SetupOnlyFans;
