import React from 'react';
import Verified from '../assets/icons/verified.svg';

const Help = () => {
  return (
    <main className="main mb-10 w-full max-w-2xl mx-auto lg:py-4 px-4">
      <div className="font-semibold text-gray-700 text-xl">Getting Started</div>
      <div className="flex flex-col border-xl border-[1px] border-gray-200 p-4 mt-4">
        <div className="font-semibold text-gray-700">Connecting OnlyFans</div>
        <div className="text-gray-600 mt-2">
          Head to the{' '}
          <a
            className="text-cbf-primary semi-bold"
            href="/dashboard/profile"
            target="_blank"
          >
            Profile
          </a>{' '}
          page. You'll be instructed to create a new "Tracking Link" on
          OnlyFans. You can create a tracking link here:{' '}
          <a
            className="text-cbf-primary semi-bold"
            href="https://onlyfans.com/my/settings/tracking-links"
            target="_blank"
          >
            Create a tracking link
          </a>
          <br />
          <br />
          This tracking link will enable you to see how many views and sales
          you're getting from your CouldBeFans page.
        </div>
      </div>
      <div className="flex flex-col border-xl border-[1px] border-gray-200 p-4 mt-4">
        <div className="font-semibold text-gray-700">Uploading Media</div>
        <div className="text-gray-600 mt-2">
          Before you can create a campaign you'll need to upload pictures and
          media for the CouldBeFans team to review. You can do this on the{' '}
          <a
            className="text-cbf-primary semi-bold"
            href="/dashboard/media"
            target="_blank"
          >
            Media
          </a>{' '}
          page. CouldBeFans reviews this content within 48 hours so you can get
          set up quickly.
          <br />
          <br />
          <div className="flex items-center">
            <img src={Verified} className="w-[50px] w-[50px]" />
            You'll see this icon once it's been verified.
          </div>
          <br />
          Note that media is limited to 10MB and only supports JPEGs, PNGs, and
          animated GIFs.
        </div>
      </div>
      <div className="flex flex-col border-xl border-[1px] border-gray-200 p-4 mt-4">
        <div className="font-semibold text-gray-700">
          Creating Your Campaign
        </div>
        <div className="text-gray-600 mt-2">
          Campaigns are created by setting up five emails that will go out to
          people who request your free content. Each email consists of:
          <ul>
            <li>- An image or animated GIF</li>
            <li>- A subject line</li>
            <li>- A title</li>
            <li>- A subtitle</li>
          </ul>
          <br />
          Once your five emails are set up on the{' '}
          <a
            className="text-cbf-primary semi-bold"
            href="/dashboard/campaign"
            target="_blank"
          >
            Campaign
          </a>{' '}
          page, you can then set your campaign to live using the toggle at the
          top.
          <br />
          <br />
          Subject lines, titles, and subtitles are all chosen from a preselected
          list. This saves you time having to get creative and keeps you and
          CouldBeFans users safe from illegal solicitation.
          <br />
          <br />
          Links to your OnlyFans, your profile picture, and all the required
          things like "unsubscribe" buttons are automatically added to your
          emails and content.
        </div>
      </div>
    </main>
  );
};

export default Help;
