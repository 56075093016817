import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const types = {
  light: 'bg-cbf-primary-light text-cbf-primary',
  action: 'bg-cbf-primary text-white',
  cancel: 'border-[1px] border-gray-300 text-cbf-back bg-white',
};

const loaders = {
  light: '#7F56D9',
  action: '#FFFFFF',
  cancel: '#7F56D9',
};

const Button = ({ title, onClick, disabled, type, className, loading }) => {
  return (
    <button
      className={`rounded-lg px-4 py-3 font-semibold text-md flex justify-center items-center whitespace-nowrap ${
        types[type] || ''
      } ${disabled ? 'opacity-50' : ''} ${className}`}
      onClick={() => {
        if (disabled || loading) return;
        onClick();
      }}
      disabled={disabled}
    >
      {loading ? (
        <ThreeDots
          height="100%"
          width="30"
          radius="9"
          color={loaders[type]}
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <div>{title}</div>
      )}
    </button>
  );
};

export default Button;
