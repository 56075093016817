import axios from 'axios';
import { apiUrlForPath } from './api';

export const adminGetCreators = async () => {
  return axios.get(apiUrlForPath('admin/creators'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const adminPutCreatorVerified = async (id, verified) => {
  return axios.post(
    apiUrlForPath('admin/verify_creator'),
    { creator_id: id, verified },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const adminGetUnverifiedMedia = async () => {
  return axios.get(apiUrlForPath('admin/unverified_media'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const adminPutMediaVerified = async (id, accepted) => {
  return axios.post(
    apiUrlForPath('admin/verify_media'),
    { media_id: id, accepted },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const adminGetStats = async () => {
  return axios.get(apiUrlForPath('admin/stats'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};
