import ReactGA from 'react-ga4';
import CookieConsentComponent, {
  getCookieConsentValue,
  Cookies,
} from 'react-cookie-consent';
import { useEffect } from 'react';

const handleAcceptCookies = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
};

const handleRejectCookes = () => {
  //remove google analytics cookies
  Cookies.remove('_ga');
  Cookies.remove('_gat');
  Cookies.remove('_gid');
};

const CookieConsent = () => {
  useEffect(() => {
    const consented = getCookieConsentValue();
    if (consented) {
      handleAcceptCookies();
    }
  }, []);

  return (
    <CookieConsentComponent
      enableDeclineButton
      disableStyles
      onAccept={() => handleAcceptCookies()}
      onDecline={() => handleRejectCookes()}
      containerClasses="fixed w-full bg-gray-100 flex p-2 items-center gap-4 text-center justify-between"
      contentClasses="text-cbf-primary grow text-sm md:text-md"
      buttonClasses="w-full rounded-lg px-4 py-3 font-semibold text-md whitespace-nowrap bg-cbf-primary text-white"
      declineButtonClasses="w-full rounded-lg px-4 py-3 font-semibold text-md whitespace-nowrap border-[1px] border-gray-300 text-cbf-back bg-white"
      buttonWrapperClasses="flex flex-col md:flex-row gap-2"
    >
      This website uses cookies to enhance the user experience.
    </CookieConsentComponent>
  );
};

export default CookieConsent;
