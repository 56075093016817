import { createSlice } from '@reduxjs/toolkit';
import Content from '../assets/data/content';
import Subjects from '../assets/data/subjects';
export const emptyCampaignItem = {};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    items: [
      emptyCampaignItem,
      emptyCampaignItem,
      emptyCampaignItem,
      emptyCampaignItem,
      emptyCampaignItem,
    ],
    editingCampaignIndex: -1,
  },
  reducers: {
    setCampaignItems: (state, action) => {
      const c = action.payload;
      while (c.length < 5) {
        c.push({});
      }
      while (c.length > 5) {
        c.pop();
      }
      state.items = c;
    },
    setEditingCampaignIndex: (state, action) => {
      state.editingCampaignIndex = action.payload;
    },
    setSubjectAtIndex: (state, action) => {
      const items = state.items;
      items[action.payload.index].subject = action.payload.subject;
      state.itesm = items;
    },
    setMediaAtIndex: (state, action) => {
      const items = state.items;
      if (!action.payload.media) {
        delete items[action.payload.index].media;
        delete items[action.payload.index].media_id;
      } else {
        items[action.payload.index].media = action.payload.media;
        items[action.payload.index].media_id = action.payload.media.id;
      }
      state.items = items;
    },
    setContentAtIndex: (state, action) => {
      const items = state.items;
      if (Object.keys(action.payload.content).length == 0) {
        delete items[action.payload.index].title;
        delete items[action.payload.index].caption;
      } else {
        items[action.payload.index].title = action.payload.content.title;
        items[action.payload.index].caption = action.payload.content.caption;
      }
      state.items = items;
    },
  },
});

const isContentValid = (title, caption) => {
  for (let index in Content) {
    if (Content[index].title === title && Content[index].caption === caption) {
      return true;
    }
  }
  return false;
};

export const getCampaignError = (campaign) => {
  if (campaign.items.length !== 5) {
    return 'Campaign must include 5 items';
  }
  for (let index in campaign.items) {
    const itemError = getCampaignItemError(campaign.items[index]);
    if (itemError) {
      return itemError;
    }
  }
  return null;
};

export const getCampaignItemError = (campaignItem) => {
  if (!campaignItem.media_id) {
    return 'Each day must include a piece of media';
  } else if (!campaignItem.caption) {
    return 'Each day must include a caption';
  } else if (!campaignItem.title) {
    return 'Each day must include a title';
  } else if (!campaignItem.subject) {
    return 'Each day must include a subject';
  } else if (!Subjects.includes(campaignItem.subject)) {
    return 'Invalid subject in campaign';
  } else if (!isContentValid(campaignItem.title, campaignItem.caption)) {
    return 'Invalid title / caption in campaign';
  }
  return null;
};

export const {
  setCampaignItems,
  setSubjectAtIndex,
  setMediaAtIndex,
  setContentAtIndex,
  setEditingCampaignIndex,
} = campaignSlice.actions;
export default campaignSlice.reducer;
