import React from 'react';

const WhatWeDo = () => {
  return (
    <section className="w-full p-4 lg:p-20 flex flex-col items-center">
      <div className="text-cbf-primary font-semibold border-b-[1px] border-cbf-primary">
        <p>What we do</p>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        <LargeCard
          title="Email Collection"
          subtitle="We build you a 100% verified email list"
          content="Every other business in the world thrives on email. So why don't
            you? By collecting emails, we automatically grow you another channel
            to reach out to a broader audience. These emails are 100% verified
            and have actively shown interest specifically in you."
        />
        <LargeCard
          title="Automatic Outreach"
          subtitle="We autoconvert people into paying subs"
          content="Our technology uses AI and automation to build a powerful set of
            emails that go out to your email list automatically. By sending out these emails, we put you in front of more people more
            often. This reminds them how amazing your content is and drives
            people back to your OnlyFans to subscribe."
        />
      </div>
    </section>
  );
};

const LargeCard = ({ title, subtitle, content }) => {
  return (
    <div className="w-full flex flex-col items-center p-4">
      <div className="text-left w-full font-bold text-sm text-gray-400">
        {title}
      </div>
      <div className="text-left w-full font-semibold text-gray-900 text-[32px]">
        {subtitle}
      </div>
      <div className="text-gray-600 mt-2">{content}</div>
    </div>
  );
};

export default WhatWeDo;
