import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { setProfile } from '../state/userSlice';
import UserHeader from '../components/UserHeader';
import { getUser, updateUser } from '../api/profile';
import ProfileMedia from '../components/util/ProfileMedia';
import SelectMediaButton from '../components/util/SelectMediaButton';
import { skins } from '../assets/skins/index';
import MediaManager from '../components/MediaManager';
import Button from '../components/util/Button';
import SubscribeLinkCopy from '../components/SubscribeLinkCopy';
const _ = require('lodash');

function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [edits, setEdits] = useState({});
  const [showMediaManager, setShowMediaManager] = useState(false);
  const [onlyFansError, setOnlyFansError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setEdits(profile);
  }, [profile]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));
      try {
        const response = await getUser();
        dispatch(setProfile(response.data));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, []);

  const save = async () => {
    setError(null);
    dispatch(setLoading('OPAQUE'));
    try {
      const updates = { ...edits };
      if (updates.media) {
        updates.media_id = updates.media.id;
      }
      const response = await updateUser(updates);
      const user = await getUser();
      dispatch(setProfile(user.data));
    } catch (e) {
      console.log(e);
      setError(e.response.data.error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <main className="main mb-10 w-full max-w-2xl mx-auto lg:py-4">
      <UserHeader profile={profile} />
      <div className="flex flex-col items-center">
        <div className="font-semibold text-[24px] text-gray-800 text-center mt-6">
          {profile?.only_fans ? `${profile.only_fans}` : ''}
        </div>
        <SubscribeLinkCopy username={profile?.only_fans} />
      </div>

      <div className="flex flex-col border-xl border-[1px] border-gray-200 m-4 p-4">
        <div className="">
          <div className="text-gray-700 font-medium text-sm">OnlyFans Link</div>
          <div className="flex w-full">
            <div className="text-gray-900 p-3 grow border-[1px] border-gray-300 rounded-r-lg border-l-1 rounded-l-lg mt-2">
              <input
                className="w-full"
                placeholder="https://onlyfans.com/myusername/c1"
                value={edits ? edits.only_fans_link : ''}
                onChange={(event) => {
                  let url = event.target.value;
                  url = url.replace('http://', '').replace('https://', '');
                  url = url.replace('onlyfans.com/', '');
                  const [username, tag] = url.split('/c');
                  if (!username || !tag || isNaN(tag)) {
                    setOnlyFansError('Not a valid tracking link');
                  } else {
                    setOnlyFansError(null);
                  }
                  setEdits({ ...edits, only_fans_link: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="text-red-500 text-sm font-semibold">
            {onlyFansError}
          </div>
          <div className="text-gray-600 text-xs mt-2">
            To best track your campaign with accurate statistics, this should be
            a tracking link for OnlyFans. You can generate one by clicking here:{' '}
            <a
              href="https://onlyfans.com/my/settings/tracking-links"
              target="_blank"
              rel="noopener noreferrer"
              className="text-cbf-primary"
            >
              Create a tracking link
            </a>
          </div>
          <div className="mt-4">
            {showMediaManager ? (
              <MediaManager
                onSelection={(item) => {
                  setEdits({ ...edits, media: item });
                  setShowMediaManager(false);
                }}
                onCancel={() => {
                  setShowMediaManager(false);
                }}
              />
            ) : (
              <div className="flex items-center gap-3">
                <div className="w-1/4">
                  <ProfileMedia media={edits?.media} />
                </div>
                <div className="w-3/4">
                  <SelectMediaButton
                    onClick={() => {
                      setShowMediaManager(true);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="text-gray-700 font-medium text-sm mt-4">
            Select banner
          </div>
          <div className="grid grid-cols-2 gap-2">
            {Object.keys(skins).map((skin) => {
              return (
                <div
                  className={`w-full h-[50px] md:h-[100px] bg-cbf-primary rounded-md overflow-hidden cursor-pointer ${
                    skin === edits?.skin ? 'p-1' : ''
                  }`}
                  onClick={() => {
                    setEdits({ ...edits, skin });
                  }}
                  key={skin}
                >
                  <img
                    src={skins[skin]}
                    className="w-full h-full object-cover"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Button
          title="Save"
          onClick={() => {
            save();
          }}
          type="action"
          disabled={_.isEqual(edits, profile)}
          className="mt-4"
        />
        {error && <div className="text-red-500">{error}</div>}
      </div>
    </main>
  );
}

export default Profile;
