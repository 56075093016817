import axios from 'axios';
import { apiUrlForPath } from './api';

export const getUser = async () => {
  return axios.get(apiUrlForPath('profile'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const updateUser = async (profile) => {
  return axios.post(
    apiUrlForPath('profile'),
    { profile },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const agreeToTerms = async () => {
  return axios.post(
    apiUrlForPath('profile/agree'),
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const getProfile = async (username) => {
  return axios.get(apiUrlForPath(`profile/${username}`));
};
