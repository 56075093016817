import React from 'react';
import ReactMarkdown from 'react-markdown';
import Markdown from './DMCAPolicyMarkdown';

const DMCAPolicy = () => {
  return (
    <main className="main w-full mb-10 p-4 min-h-[400px] flex justify-center">
      <ReactMarkdown className="prose">{Markdown}</ReactMarkdown>
    </main>
  );
};

export default DMCAPolicy;
