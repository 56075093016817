import React, { useEffect, useState } from 'react';
import { voteMedia } from '../../api/media';
import Flame from '../../assets/icons/flame.svg';

const MediaVote = ({ mediaId, subscriptionId }) => {
  const [vote, setVote] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        await voteMedia(mediaId, vote, subscriptionId);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [vote]);

  return (
    <div>
      <div className="font-semibold text-gray-900 text-center">
        How hot is this?
      </div>
      <div className="flex justify-center items-center">
        {Array(5)
          .fill(0)
          .map((item, index) => {
            return (
              <img
                src={Flame}
                className={`w-[40px] h-[40px] cursor-pointer ${
                  index >= vote ? 'opacity-50' : 'opacity-100'
                }`}
                key={index}
                onClick={() => {
                  setVote(index + 1);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default MediaVote;
