import React from 'react';

const Problem = () => {
  return (
    <section className="w-full py-10 px-4 md:py-4 lg:p-20 flex flex-col items-center bg-gray-100 text-center">
      <div className="text-cbf-primary font-semibold border-b-[1px] border-cbf-primary">
        <p>The Problem</p>
      </div>
      <div className="text-gray-900 font-semibold text-[32px]">
        You're missing out on paying customers
      </div>
      <div className="text-gray-600 text-[20px] mt-2">
        Earning more $$ from more people is about connecting with them and
        showing them why you're great.
      </div>
      <div className="text-gray-600 text-[20px] mt-2">
        You need more ways to be in touch with{' '}
        <span className="text-cbf-primary font-bold">real people</span> who are
        actually{' '}
        <span className="text-cbf-primary font-bold">
          interested in subscribing
        </span>
        .
      </div>
    </section>
  );
};

export default Problem;
