import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedia } from '../api/media';
import MediaSelection from '../components/MediaSelection';
import { setMediaItems } from '../state/mediaSlice';
import { setLoading } from '../state/uiSlice';
import { putPassword } from '../api/auth';
import Button from '../components/util/Button';

const Account = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const submit = async () => {
    if (newPassword.length < 8) {
      return setPasswordError('Password must be at least 8 characters long');
    }
    setPasswordError(null);
    dispatch(setLoading(true));
    try {
      const response = await putPassword(oldPassword, newPassword);
      console.log(response);
      setOldPassword('');
      setNewPassword('');
      setNewPasswordCheck('');
    } catch (e) {
      console.log(e);
      setPasswordError(e.response.data.error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <main className="main mb-10 w-full max-w-2xl mx-auto p-4">
      <div className="flex flex-col border-xl border-[1px] border-gray-200 m-4 p-4">
        <div className="font-semibold">Update Password</div>
        <div className="input mt-3">
          <input
            type="password"
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Old Password"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={oldPassword}
          />
        </div>
        <div className="input mt-3">
          <input
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={newPassword}
          />
        </div>
        <div className="input mt-3">
          <input
            type="password"
            onChange={(e) => setNewPasswordCheck(e.target.value)}
            placeholder="Confirm New Password"
            className="block w-full mx-auto mt-2 border border-gray-300 px-4 py-2.5 rounded-lg"
            value={newPasswordCheck}
          />
        </div>
        <Button
          title="Update Password"
          type="action"
          onClick={() => {
            submit();
          }}
          disabled={
            !oldPassword ||
            newPassword.length == 0 ||
            newPassword != newPasswordCheck
          }
          className="mt-3"
        />
        {passwordError && <div className="text-red-500">{passwordError}</div>}
      </div>
    </main>
  );
};

export default Account;
