import React from 'react';
import Button from '../../components/util/Button';
import { useLocation } from 'react-router-dom';

const WaitlistBold = () => {
  const scrollToSection = (section) => {
    setTimeout(() => {
      const element = document.getElementById(section);
      element.scrollIntoView();
    }, 0);
  };

  return (
    <section className="w-full flex justify-center p-10">
      <div className="bg-cbf-primary rounded-2xl py-20 flex flex-col items-center w-full">
        <div className="text-white font-semibold text-[32px] text-center">
          Join Early Access
        </div>
        <div className="text-white/80 text-center mb-10 p-2">
          Get in early to be a part of all the creators trusting CouldBeFans to
          grow their paid audience.
        </div>
        <Button
          title="Get on the list"
          type="light"
          onClick={() => {
            scrollToSection('waitlist');
          }}
        />
      </div>
    </section>
  );
};

export default WaitlistBold;
