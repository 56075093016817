import React from 'react';
import SubscribeLinkCopy from './SubscribeLinkCopy';
import Button from './util/Button';

const GoLivePopup = ({ onClose, profile }) => {
  const handleChildElementClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-gray-800 bg-opacity-80 z-[1000]"
      onClick={() => {
        onClose();
      }}
    >
      <div
        className="rounded-lg bg-white p-4 flex flex-col items-center"
        onClick={(e) => {
          handleChildElementClick(e);
        }}
      >
        <div className="font-semibold">Your campaign is live!</div>
        <div className="mb-4">
          Copy this link and add it to your Linktree or other websites
        </div>
        <SubscribeLinkCopy username={profile?.only_fans} />
        <Button
          type="action"
          title="Copy Link"
          onClick={() => {
            if (!profile?.only_fans) return;
            navigator.clipboard.writeText(
              `https://couldbefans.com/subscribe/${profile.only_fans}`
            );
            onClose();
          }}
          className="mt-4"
        />
      </div>
    </div>
  );
};

export default GoLivePopup;
