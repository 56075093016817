import React from "react";
import Button from "../../components/util/Button";
import HeroImage from "./assets/hero.png";

const Hero = () => {
  const scrollToSection = (section) => {
    setTimeout(() => {
      const element = document.getElementById(section);
      element.scrollIntoView();
    }, 0);
  };

  return (
    <section className="w-full bg-cbf-primary/10 lg:max-h-[800px] flex justify-center">
      <div className="flex flex-col items-center lg:flex-row justify-between p-4 lg:p-20 max-w-[1200px]">
        <div className="flex flex-col justify-center lg:items-start max-w-[600px]">
          <div className="text-center lg:text-start text-[60px] font-semibold text-gray-900 leading-[60px]">
            Grow your
            <span className="text-cbf-primary"> OnlyFans</span>
          </div>
          <div className="w-full text-[20px] text-gray-600 mt-4 text-center">
            Make more $$ automatically by marketing through email.
          </div>
          <Button
            title="Join Early Access"
            type="action"
            onClick={() => {
              scrollToSection("waitlist");
            }}
            className="mt-4 w-full"
          />
          <div className="flex flex-col md:flex-row justify-center w-full mt-4 gap-2">
            <StatCard
              value="50%"
              stat="Emails Opened"
              subtext="3x the global average!"
            />
            <StatCard
              value="100%"
              stat="Verified Addresses"
              subtext="Not bots. Real viewers!"
            />
          </div>
          <div className="w-full text-center text-[20px] text-gray-600 mt-4">
            More eyes on you means you{" "}
            <span className="text-cbf-primary font-bold">make more $$</span>.
          </div>
        </div>
        <div className="mt-10 lg:mt-0 max-h-[550px] lg:max-h-none flex justify-center h-full overflow-hidden hidden lg:block">
          <img src={HeroImage} className="h-full drop-shadow-xl" />
        </div>
      </div>
    </section>
  );
};

const StatCard = ({ value, stat, subtext }) => {
  return (
    <div className="bg-white shadow-lg border-[2px] border-gray-200 rounded-lg flex flex-col items-center p-4 text-center">
      <div className="text-3xl text-cbf-primary">{value}</div>
      <div className="text-gray-500 font-semibold">{stat}</div>
      <div className="text-sm text-gray-500">{subtext}</div>
    </div>
  );
};

export default Hero;
