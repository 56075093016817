import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserHeader from '../components/UserHeader';
import MediaView from '../components/MediaView';
import { getView } from '../api/view';
import Button from '../components/util/Button';
import TripleDot from '../assets/icons/triple-dot.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import ReactGA from 'react-ga4';
import Notification from '../assets/icons/notification-important.svg';
import PulseIcon from '../components/util/PulseIcon';
import Cancel from '../assets/icons/cancel.svg';
import { mediaUrl } from '../api/media';
import MediaVote from '../components/util/MediaVote';

const View = ({}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [view, setView] = useState(null);
  const [creator, setCreator] = useState(null);
  const [showReport, setShowReport] = useState(null);
  const [revealed, setRevealed] = useState(false);
  const [error, setError] = useState(null);
  const [upsellModal, setUpsellModal] = useState(false);

  useEffect(() => {
    const id = params.id;
    const loadContent = async () => {
      dispatch(setLoading('OPAQUE'));
      try {
        const response = await getView(id);
        setView(response.data.view);
        setCreator(response.data.creator);
        console.log(response);
      } catch (e) {
        if (e.response.status == 404) {
          return setError(404);
        }
        console.log(e);
      } finally {
        dispatch(setLoading(false));
      }
    };
    loadContent();
  }, [params]);

  const openOnlyFans = () => {
    ReactGA.event({
      category: 'conversion',
      action: 'outbound_click',
      label: creator.only_fans,
    });
    window.open(
      `${process.env.REACT_APP_API_URL}/view/only_fans?link=${encodeURI(
        creator.only_fans_link
      )}`,
      '_blank',
      'noreferrer'
    );
    //window.open(creator.only_fans_link, '_blank', 'noreferrer');
  };

  const openReport = () => {
    window.open(`/report/${view.id}`, '_blank', 'noreferrer');
  };

  if (error == 404) {
    return (
      <main className="main w-full h-full mb-10 max-w-2xl flex flex-col items-center justify-center">
        <div className="font-semibold text-cbf-primary text-xl">Oops!</div>
        <div className="text-gray-900 mt-4">
          This content has expired or does not exist
        </div>
      </main>
    );
  }

  if (!view) {
    return null;
  }

  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col items-center">
      <UserHeader profile={creator} />
      <div className="font-semibold text-[24px] text-gray-800 text-center mt-6">
        {creator ? `${creator.only_fans}` : 'Loading...'}
      </div>
      <a
        href={creator?.only_fans_link}
        target="_blank"
        className="text-cbf-primary text-md cursor-pointer"
      >
        Subscribe to my OnlyFans
      </a>
      <div className="w-full max-w-[500px] p-4 pb-8">
        <div className="w-full flex justify-between items-center">
          <div className="font-semibold text-gray-900 py-2">
            {view?.title || ''}
          </div>
          <div className="flex items-center">
            {showReport && (
              <div
                className="rounded-lg p-2 border-[1px] border-gray-300 drop-shadow-md text-sm cursor-pointer"
                onClick={() => {
                  openReport();
                }}
              >
                Report Content
              </div>
            )}
            <div
              className="w-[30px] h-full cursor-pointer flex justify-center"
              onClick={() => {
                setShowReport(!showReport);
              }}
            >
              <img src={TripleDot} className="cursor-pointer h-full" />
            </div>
          </div>
        </div>
        <div className="text-gray-600 text-sm">{view?.caption || ''}</div>
        <div className="flex flex-col overflow-hidden mt-4 relative">
          <MediaView
            mediaUrl={mediaUrl(view?.media)}
            mimeType={view?.media.mime_type}
            blurred={!revealed}
          />

          {!revealed && (
            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
              <div
                className="bg-cbf-primary px-8 py-2 text-white cursor-pointer rounded-md"
                onClick={() => {
                  setTimeout(() => {
                    setUpsellModal(true);
                  }, 2000);
                  setRevealed(true);
                }}
              >
                Reveal Content
              </div>
            </div>
          )}
          {upsellModal && (
            <div
              className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center"
              onClick={() => {
                setUpsellModal(false);
              }}
            >
              <div className="bg-white border-[1px] border-gray-100 rounded-lg p-4 flex flex-col backdrop-blur bg-opacity-80 m-4">
                <div className="flex justify-between items-start">
                  <PulseIcon className="h-[40px] w-[40px]">
                    <div className="w-full h-full flex items-center justify-center">
                      <img src={Notification} />
                    </div>
                  </PulseIcon>
                  <img
                    className="cursor-pointer"
                    src={Cancel}
                    onClick={() => {
                      setUpsellModal(false);
                    }}
                  />
                </div>
                <div className="font-semibold text-gray-900 mt-4">
                  Wanting a little more?
                </div>
                <div className="text-gray-700">
                  {view.index < 5
                    ? "Don't worry, more content is on it's way tomorrow, but why wait until then? You know where to find me..."
                    : "There's more to be discovered. You know where to find me..."}
                </div>
                <Button
                  title="Check out my OnlyFans"
                  onClick={() => {
                    openOnlyFans();
                  }}
                  type="action"
                  className="w-full mt-4"
                />
              </div>
            </div>
          )}
        </div>
        {view && (
          <MediaVote
            mediaId={view?.media.id}
            subscriptionId={view?.subscription_id}
          />
        )}

        {view.index < 5 && (
          <div className="text-gray-600 mt-4">{`${5 - view.index} more day${
            view.index == 4 ? '' : 's'
          } of content are headed your way! Unless you don't want to wait...`}</div>
        )}
        <div className="font-semibold text-lg text-gray-900 w-full text-center mt-4">
          Ready for more?
        </div>
        <Button
          title={'Join my OnlyFans'}
          onClick={() => {
            openOnlyFans();
          }}
          type="action"
          className="w-full bg-[#0086C9] mt-4"
        />
      </div>
    </main>
  );
};

export default View;
