import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../state/uiSlice';
import { adminGetStats } from '../../api/admin';
import LineChart from '../../components/LineChart';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

const AdminStats = () => {
  const dispatch = useDispatch();

  const [stats, setStats] = useState(null);

  const loadStats = async () => {
    dispatch(setLoading(true));
    try {
      const response = await adminGetStats();
      setStats(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    loadStats();
  }, []);

  if (!stats) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row items-center justify-center justify-between">
        <div className="flex flex-col items-center">
          <div className="text-[36px]">
            {parseFloat(
              stats.verifiedPercentage[0].verified_percentage
            ).toFixed(2)}
            %
          </div>
          <div className="">
            of {stats.verifiedPercentage[0].count} addresses verified
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-[36px]">{stats.emailCount[0].count}</div>
          <div className="">emails sent</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-[36px]">
            {parseFloat(stats.viewPercentage[0].view_percentage).toFixed(2)}%
          </div>
          <div className="">
            of {stats.viewPercentage[0].count} emails viewed
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center max-h-[200px] my-10">
        <div className="font-semibold mb-2">Subscriptions by Creator</div>
        <PieChart
          title="Subscriptions by Creator"
          data={stats.subscriptionsByCreator.slice(0, 10).map((item) => ({
            creator: item.only_fans,
            subscriptions: parseInt(item.count || 0),
          }))}
          xAxis="creator"
          yAxis="subscriptions"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-10 mt-10">
        <div className="">
          <div className="font-semibold">Campaign Subscriptions</div>
          <LineChart
            title="Campaign Subscriptions"
            data={stats.subscriptionsByDay.map((item) => ({
              date: new Date(item.day).toLocaleDateString('en-US'),
              subscribes: parseInt(item.count || 0),
            }))}
            xAxis="date"
            yAxis="subscribes"
          />
        </div>
        <div className="">
          <div className="font-semibold">Emails Sent</div>
          <LineChart
            title="History"
            data={stats.emailsByDay.map((item) => ({
              date: new Date(item.day).toLocaleDateString('en-US'),
              sends: parseInt(item.count || 0),
            }))}
            xAxis="date"
            yAxis="sends"
          />
        </div>
        <div className="">
          <div className="font-semibold">Content Views</div>
          <LineChart
            title="History"
            data={stats.viewsByDay.map((item) => ({
              date: new Date(item.day).toLocaleDateString('en-US'),
              views: parseInt(item.count || 0),
            }))}
            xAxis="date"
            yAxis="views"
          />
        </div>
        <div className="">
          <div className="font-semibold">Opens By Campaign Index</div>
          {stats && (
            <BarChart
              title="View Indices"
              data={stats.viewIndices.map((item) => ({
                index: item.index,
                views: parseInt(item.views || 0),
              }))}
              xAxis="index"
              yAxis="views"
              type="bar"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminStats;
