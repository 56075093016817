import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { agreeToTerms } from '../api/profile';
import Button from '../components/util/Button';
import { setLoading } from '../state/uiSlice';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import TermsOfUse from './legal/TermsOfUseMarkdown';
import TermsOfService from './legal/TermsOfServiceMarkdown';
import { decodeToken } from 'react-jwt';
import Checkbox from '../components/util/Checkbox';

const CreatorAgreement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [over18, setOver18] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const agree = async () => {
    dispatch(setLoading(true));
    try {
      const response = await agreeToTerms();
      const token = response.data.jwt;
      localStorage.setItem('auth_token', token);
      navigate('/dashboard/profile');
    } catch (e) {
      console.log(e);
      setError('Failed to agree to terms');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const token = localStorage.getItem('auth_token');
  const user = decodeToken(token);
  console.log(user);

  return (
    <main className="main w-full mb-10 flex flex-col p-4 items-center">
      <h2 className="mt-0 text-2xl font-semibold">Agreement</h2>
      <div className="flex w-full flex-col items-center justify-evenly mt-10 gap-2">
        <div className="flex flex-col border-gray-200 border-[1px] rounded-lg p-4 ">
          <div className="h-[600px] overflow-scroll flex justify-center">
            <ReactMarkdown className="prose">{TermsOfUse}</ReactMarkdown>
          </div>
        </div>
        <div className="flex flex-col border-gray-200 border-[1px] rounded-lg p-4">
          <div className="h-[600px] overflow-scroll flex justify-center">
            <ReactMarkdown className="prose">{TermsOfService}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className="max-w-2xl mt-10">
        <div className="flex gap-4 mt-2 mb-4 font-medium text-gray-700">
          <Checkbox
            value={over18}
            onChange={() => {
              setOver18(!over18);
            }}
          />
          <p
            onClick={() => {
              setOver18(!over18);
            }}
          >
            I certify I am at least 18-years old and have reached the age of
            majority where I reside.
          </p>
        </div>
        <div className="flex gap-4 mt-2 mb-4 font-medium text-gray-700">
          <Checkbox
            value={termsAgreed}
            onChange={() => {
              setTermsAgreed(!termsAgreed);
            }}
          />
          <p
            onClick={() => {
              setTermsAgreed(!termsAgreed);
            }}
          >
            I certify I have read and agree to the{' '}
            <a
              href="/terms-of-use"
              target="_blank"
              className="text-cbf-primary underline"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Terms of Use Agreement
            </a>{' '}
            and the{' '}
            <a
              href="/terms-of-use"
              target="_blank"
              className="text-cbf-primary underline"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Terms of Service.
            </a>
          </p>
        </div>
        <Button
          title="I Accept"
          type="action"
          onClick={() => {
            agree();
          }}
          disabled={!over18 || !termsAgreed}
          className="w-full"
        />
        {error && <div className="text-red-500">{error}</div>}
      </div>
    </main>
  );
};

export default CreatorAgreement;
