import React, { useMemo } from 'react';
import { Chart } from 'react-charts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const BarChart = ({ title, data, xAxis, yAxis }) => {
  if (!data) return null;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: title,
      },
    },
  };
  const labels = data.map((d) => d[xAxis]);

  const chartData = {
    labels,
    datasets: [
      {
        label: yAxis,
        data: data.map((d) => d[yAxis]),
        borderColor: '#7F56D9',
        backgroundColor: '#7F56D9',
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
};

export default BarChart;
