import React, { useEffect, useState } from 'react';
import Button from './util/Button';
import Subjects from '../assets/data/subjects.js';
import Content from '../assets/data/content.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMediaAtIndex,
  setSubjectAtIndex,
  setContentAtIndex,
  setEditingCampaignIndex,
  getCampaignItemError,
} from '../state/campaignSlice';
import { setLoading } from '../state/uiSlice';
import { updateCampaignItem } from '../api/campaign';
import MediaManager from './MediaManager';
import SelectMediaButton from './util/SelectMediaButton';
import MediaSelection from './MediaSelection';

const EditCampaignItem = ({ index }) => {
  const dispatch = useDispatch();
  const savedItem = useSelector((state) => state.campaign.items[index]);
  const [media, setMedia] = useState(null);
  const [subject, setSubject] = useState(null);
  const [contentIndex, setContentIndex] = useState(-1);
  const [showMediaManager, setShowMediaManager] = useState(false);

  useEffect(() => {
    setMedia(savedItem.media);
    setSubject(savedItem.subject ? savedItem.subject : '');
    for (let index in Content) {
      if (savedItem.title === Content[index].title) {
        setContentIndex(index);
        break;
      }
    }
  }, [savedItem]);

  const item = {
    media,
    media_id: media?.id,
    subject,
    caption: Content[contentIndex]?.caption,
    title: Content[contentIndex]?.title,
  };

  const isDataSaved = () => {
    if (contentIndex < 0) return true;
    if (savedItem.media_id != media?.id) return false;
    if (savedItem.subject != subject) return false;
    if (savedItem.title != Content[contentIndex].title) return false;
    return true;
  };

  const isItemIncomplete = getCampaignItemError(item);
  const dataIsUnsaved = !isDataSaved();
  const isAnUpdate = Object.keys(savedItem).length != 0;

  const save = async () => {
    if (isItemIncomplete) {
      return;
    }
    dispatch(setLoading(true));
    try {
      const response = await updateCampaignItem(item, index);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="w-full flex flex-col p-4 border-2 rounded-xl border-gray-300 border-solid">
      <div className="text-gray-700 font-medium">Email {index + 1} content</div>
      <div className="text-gray-600 font-normal">
        Upload a photo and select a caption
      </div>
      <div className="mt-6">
        <div className="text-gray-700 font-medium mb-2">Content Image</div>
        {showMediaManager ? (
          <div className="flex justify-center">
            <MediaManager
              onSelection={(item) => {
                setMedia(item);
                setShowMediaManager(false);
              }}
              onCancel={() => {
                setShowMediaManager(false);
              }}
            />
          </div>
        ) : media ? (
          <div className="relative w-full">
            <MediaSelection item={media} />
            <div className="absolute flex items-center justify-center top-4 left-4">
              <Button
                title="Click to Edit"
                onClick={() => {
                  setShowMediaManager(true);
                }}
                type="action"
              />
            </div>
          </div>
        ) : (
          <SelectMediaButton
            onClick={() => {
              setShowMediaManager(true);
            }}
          />
        )}
      </div>
      <div className="mt-6">
        <div className="text-gray-700 font-medium">Email Subject Line</div>
        <select
          className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block mt-2"
          value={subject ? subject : ''}
          onChange={(event) => {
            setSubject(event.target.value);
          }}
        >
          <option value="">Select a subject line</option>
          {Subjects.map((subject) => {
            return (
              <option
                key={subject}
                className="whitespace-pre-wrap text-ellipsis overflow-hidden"
                value={subject}
              >
                {subject}
              </option>
            );
          })}
        </select>
      </div>
      <div className="mt-6">
        <div className="text-gray-700 font-medium">Content Title</div>
        <select
          className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block mt-2"
          value={contentIndex}
          onChange={(event) => {
            if (event.target.value === 'null') {
              return setContentIndex(-1);
            }
            setContentIndex(event.target.value);
          }}
        >
          <option value="null">Select a content title</option>
          {Content.map((content, index) => {
            return (
              <option
                key={content.title}
                className="whitespace-pre-wrap text-ellipsis overflow-hidden"
                value={index}
              >
                {content.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-sm font-regular text-gray-600 mt-2">
        Selecting a content title will automatically generate the caption below
      </div>
      <div className="text-gray-700 font-medium mt-4">Content Caption</div>
      <div className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block">
        {contentIndex < 0 ? '---' : Content[contentIndex].caption}
      </div>
      <div className="flex justify-end mt-6 gap-2">
        <Button
          title="Cancel"
          onClick={() => {
            dispatch(setEditingCampaignIndex(-1));
          }}
          type="cancel"
        />
        <Button
          title={isAnUpdate ? 'Save' : 'Add content'}
          onClick={async () => {
            const success = await save();
            if (!success) return;
            dispatch(setMediaAtIndex({ index, media }));
            dispatch(setSubjectAtIndex({ index, subject }));
            dispatch(
              setContentAtIndex({ index, content: Content[contentIndex] })
            );
            dispatch(setEditingCampaignIndex(-1));
          }}
          type="action"
          disabled={isItemIncomplete || !dataIsUnsaved}
        />
      </div>
    </div>
  );
};

export default EditCampaignItem;
