import React from 'react';
import { useDispatch } from 'react-redux';
import { getMedia } from '../../api/media';
import Button from '../../components/util/Button';
import { setMediaItems } from '../../state/mediaSlice';
import { setLoading } from '../../state/uiSlice';
import InactiveStep from './InactiveStep';

const SetupVerification = ({ title, number }) => {
  const dispatch = useDispatch();
  const reload = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getMedia();
      dispatch(setMediaItems(response.data));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <InactiveStep title={title} number={number} completed={false} />
      <div className="text-gray-600 text-[14px] mt-6">
        We'll review your media within 24 hours (usually much sooner than that)
        and then you'll be ready to set up your CouldBeFans campaign!
      </div>
      <Button
        type="action"
        className="w-full mt-6"
        title="Refresh verification status"
        onClick={() => {
          reload();
        }}
      />
    </div>
  );
};

export default SetupVerification;
