import React from 'react';
import Button from '../../components/util/Button';
import WaitlistLoading from './WaitlistLoading';
import WaitlistSubmitted from './WaitlistSubmitted';
import Helmet from 'react-helmet';

const WaitlistLight = ({
  email,
  setEmail,
  loading,
  error,
  submitted,
  onSubmit,
}) => {
  return (
    <section
      className="w-full flex justify-center p-10 bg-gray-100"
      id="waitlist"
    >
      <div className="max-w-[600px]">
        <div className="text-gray-900 font-semibold text-[32px] text-center">
          Get Started
        </div>
        <div className="text-gray-600 text-center mb-10 text-center">
          Joining the waitlist means you'll be in the queue to get access to
          Could Be Fans as soon as space is available.
        </div>
        <div id="getWaitlistContainer" data-waitlist_id="6649"></div>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
          />
          <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
        </Helmet>
        {/*loading ? (
          <WaitlistLoading />
        ) : !submitted ? (
          <div className="flex flex-col md:flex-row w-full gap-4 mt-4 overflow-hidden">
            <input
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Email"
              className="grow rounded-lg px-4 py-4 md:py-0"
            />
            <Button
              title="Join Waitlist"
              type="action"
              onClick={() => {
                onSubmit();
              }}
              disabled={loading}
            />
          </div>
        ) : (
          <WaitlistSubmitted />
        )*/}

        {error && <div className="text-sm text-red-500 mt-2">{error}</div>}
        <div className="text-sm text-gray-600 mt-2">
          We care about your data in our{' '}
          <a href="/privacy-policy" className="underline cursor-pointer">
            privacy policy
          </a>
        </div>
      </div>
    </section>
  );
};

export default WaitlistLight;
