import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAnalytics } from '../api/analytics';
import LineChart from '../components/LineChart';
import MediaSelection from '../components/MediaSelection';
import { setLoading } from '../state/uiSlice';
import Flame from '../assets/icons/flame.svg';

function Analytics() {
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState();

  const updateAnalytics = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getAnalytics();
      console.log(response);
      dispatch(setAnalytics(response.data));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    updateAnalytics();
  }, []);

  console.log(analytics);

  if (!analytics || analytics.subscriptions.length == 0) {
    return (
      <main className="main mb-10 p-4 max-w-2xl mx-auto w-full">
        <h2 className="mt-0 text-2xl font-semibold">Analytics</h2>
        <div className="">No data collected yet</div>
      </main>
    );
  }

  return (
    <main className="main mb-10 p-4 max-w-2xl mx-auto w-full">
      <h2 className="mt-0 text-2xl font-semibold">Analytics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-10 mt-10">
        <div className="">
          <div className="font-semibold">Campaign Subscriptions</div>
          {analytics && (
            <LineChart
              title="History"
              data={analytics.subscriptions.map((item) => ({
                date: new Date(item.day).toLocaleDateString('en-US'),
                subscribes: item.count || 0,
              }))}
              xAxis="date"
              yAxis="subscribes"
            />
          )}
        </div>
        <div className="">
          <div className="font-semibold">Emails Sent</div>
          {analytics && (
            <LineChart
              title="History"
              data={analytics.emails.map((item) => ({
                date: new Date(item.day).toLocaleDateString('en-US'),
                sends: item.count || 0,
              }))}
              xAxis="date"
              yAxis="sends"
            />
          )}
        </div>
        <div className="">
          <div className="font-semibold">Content Views</div>
          {analytics && (
            <LineChart
              title="History"
              data={analytics.views.map((item) => ({
                date: new Date(item.day).toLocaleDateString('en-US'),
                contentviews: item.count || 0,
              }))}
              xAxis="date"
              yAxis="contentviews"
            />
          )}
        </div>
      </div>
      <h2 className="mt-8 text-2xl font-semibold">Media</h2>
      {analytics && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 gap-y-10">
          {analytics.mediaVotes.map((item) => {
            console.log(item);
            return (
              <div className="flex flex-col items-center max-h-[150px]">
                <MediaSelection item={item} />
                <div className="flex items-center">
                  <img src={Flame} className="h-[16px] mr-2" />
                  <div className="font-semibold w-full text-center text-cbf-primary">
                    {parseFloat(item.average_vote).toFixed(1)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </main>
  );
}

export default Analytics;
