import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../state/uiSlice";
import { getUser } from "../api/profile";
import { setProfile } from "../state/userSlice";
import { getMedia } from "../api/media";
import { setMediaItems } from "../state/mediaSlice";

const LOGIN = "login";
const UNAGREED = "unagreed";
const UNVERIFIED = "unverified";
const SETUP_REQUIRED = "setup_required";
const VERIFIED = "verified";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const mediaItems = useSelector((state) => state.media.items);
  const [route, setRoute] = useState(null);

  const reloadProfile = async () => {
    console.log("Reloading profile");
    dispatch(setLoading(true));
    try {
      const user = await getUser();
      dispatch(setProfile(user.data));
      const media = await getMedia();
      dispatch(setMediaItems(media.data));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    console.log("Loading profile");
    reloadProfile();
  }, []);

  useEffect(() => {
    console.log("setting route", selectProtectedRoute());
    setRoute(selectProtectedRoute());
  }, [profile, mediaItems]);

  useEffect(() => {
    window.addEventListener("storage", reloadProfile);

    return () => {
      window.removeEventListener("storage", reloadProfile);
    };
  }, []);

  const selectProtectedRoute = () => {
    if (!localStorage.getItem("auth_token")) {
      return LOGIN;
    }
    const token = localStorage.getItem("auth_token");
    const user = decodeToken(token);
    if (!user.agreed_at) {
      return UNAGREED;
    }
    if (!user.verified) {
      return UNVERIFIED;
    }
    if (
      mediaItems?.filter((item) => item.status == "LIVE").length < 5 ||
      !profile?.only_fans ||
      !profile?.media_id
    ) {
      return SETUP_REQUIRED;
    }
    return VERIFIED;
  };

  if (route === LOGIN) {
    return <Navigate to="/dashboard/login" />;
  } else if (route == UNAGREED && location.pathname != "/dashboard/agreement") {
    return <Navigate to="/dashboard/agreement" />;
  } else if (
    route === UNVERIFIED &&
    location.pathname != "/dashboard/unverified"
  ) {
    return <Navigate to="/dashboard/unverified" />;
  } else if (
    route == SETUP_REQUIRED &&
    location.pathname != "/dashboard/setup"
  ) {
    return <Navigate to="/dashboard/setup" />;
  }

  return children;
};

export default ProtectedRoute;
