import React, { useEffect, useState } from 'react';

const NotFound = ({}) => {
  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col text-center p-4">
      <h1>Page Not Found</h1>
    </main>
  );
};

export default NotFound;
