import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    menuVisible: false,
    loading: false,
  },
  reducers: {
    setMenuVisible: (state, action) => {
      state.menuVisible = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setMenuVisible, setLoading } = uiSlice.actions;
export default uiSlice.reducer;
