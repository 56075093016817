import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/util/Button';
import InactiveStep from './InactiveStep';

const SetupCampaign = ({ number }) => {
  const navigate = useNavigate();
  return (
    <div>
      <InactiveStep
        title="You're Verified!"
        number={number}
        completed={false}
      />
      <Button
        type="action"
        title="Get started!"
        className="w-full mt-6"
        onClick={() => {
          navigate('/dashboard/campaign');
        }}
      />
    </div>
  );
};

export default SetupCampaign;
