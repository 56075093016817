import React from 'react';
import Check from '../../assets/icons/check.svg';

const Checkbox = ({ value, onChange, disabled = false }) => {
  return (
    <div
      className={`w-[20px] h-[20px] min-w-[20px] border-2 rounded ${
        value ? 'bg-cbf-primary' : 'bg-white'
      } cursor-pointer`}
      onClick={() => {
        if (disabled) return;
        onChange();
      }}
    >
      {value && <img src={Check} />}
    </div>
  );
};

export default Checkbox;
