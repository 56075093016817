import axios from 'axios';
import { apiUrlForPath } from './api';

export const getToken = async () => {
  return axios.get(apiUrlForPath('auth/refresh'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const putPassword = async (oldPassword, newPassword) => {
  return axios.post(
    apiUrlForPath('auth/password-update'),
    { oldPassword, newPassword },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  );
};

export const forgotPassword = async (email) => {
  return axios.post(apiUrlForPath('auth/forgot-password'), { email });
};

export const resetPassword = async (id, newPassword) => {
  return axios.post(apiUrlForPath('auth/password-reset'), {
    id,
    password: newPassword,
  });
};
