import axios from 'axios';
import { apiUrlForPath } from './api';

export const subscribe = async (email, id) => {
  return axios.post(apiUrlForPath(`subscribe/${id}`), {
    email,
  });
};

export const verify = async (id) => {
  return axios.post(apiUrlForPath(`subscribe/verify/${id}`));
};

export const unsubscribe = async (id) => {
  return axios.post(apiUrlForPath(`subscribe/unsubscribe/${id}`));
};

export const globalUnsubscribe = async (email) => {
  return axios.post(apiUrlForPath('subscribe/unsubscribe'), {
    email,
  });
};
