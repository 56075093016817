import React from 'react';

import one from './assets/1.svg';
import two from './assets/2.svg';
import three from './assets/3.svg';
import four from './assets/4.svg';
import steps from './assets/steps.png';

const HowItWorks = () => {
  return (
    <section
      className="w-full p-4 lg:p-20 flex flex-col items-center bg-gray-100"
      id="howitworks"
    >
      <div className="max-w-[500px] lg:max-w-none">
        <div className="">
          <div className="text-cbf-primary font-semibold">How it works</div>
          <div className="text-gray-900 font-semibold text-[32px]">
            Automatically capture leads
          </div>
          <div className="text-gray-600 text-[20px] mt-2">
            CouldBeFans reaches out to people who have shown interest in you,
            but haven't yet subscribed.
            <span className="font-semibold text-gray-900">
              Then it shows them what they're missing.
            </span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-10 gap-10">
          <div className="flex flex-col gap-2 lg:w-2/3 max-w-[600px]">
            <div className="flex flex-col lg:flex-row gap-2">
              <Step
                title="Create your profile"
                content="Set a profile picture, connect your links, and select a custom style."
                icon={one}
              />
              <Step
                title="Design your campaign"
                content="Choose the media you want sent out to interested customers"
                icon={two}
              />
            </div>
            <div className="flex flex-col lg:flex-row gap-2 lg:mt-2">
              <Step
                title="Share your link"
                content="Receive one universal link compatible with Linktree and social media"
                icon={three}
              />
              <Step
                title="Convert leads"
                content="We automatically send emails reminding customers to subscribe"
                icon={four}
              />
            </div>
          </div>
          <div className="flex justify-center h-full lg:grow max-h-[800px]">
            <img src={steps} className="object-contain" />
          </div>
        </div>
      </div>
    </section>
  );
};

const Step = ({ icon, title, content }) => {
  return (
    <div className="w-full bg-white rounded-lg drop-shadow-md border-[1px] border-gray-200 p-4">
      <img src={icon} className="mb-4" />
      <div className="text-gray-900 font-semibold text-[20px]">{title}</div>
      <div className="text-gray-600 text-[16px]">{content}</div>
    </div>
  );
};

export default HowItWorks;
