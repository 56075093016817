import React from 'react';
import Button from './util/Button';

const EmptyCampaignItem = ({ index, onSelect }) => {
  return (
    <div className="w-full flex flex-col p-4 border-2 rounded-xl border-gray-300 border-dashed">
      <div className="text-gray-700 font-medium">Email {index + 1} content</div>
      <div className="text-gray-600 font-normal mb-4">
        Upload a photo and select a caption
      </div>
      <div className="flex justify-end">
        <Button
          title="Add content"
          onClick={() => {
            onSelect();
          }}
          type="light"
        />
      </div>
    </div>
  );
};

export default EmptyCampaignItem;
