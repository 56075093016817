export default `
**18 U.S.C. § 2257 Statement**

**Last Updated: 3/9/23**

Any actual human being that appears in any visual depiction of actual sexually explicit conduct appearing or otherwise contained on the website located at [couldbefans.com](http://www.couldbefans.com/) (" **Website**") was at least 18-years old at the time those visual depictions were produced.

**Exemption: Content Produced by Third Parties**
 The Website's operator is not the "producer" of any depictions of actual or simulated sexually explicit conduct that may appear on the Website. The Website's operator limits its handling of this content and only performs the activities of transmission, storage, retrieval, hosting, or formatting of material that may depict sexually explicit conduct, all which material appears on the Website as the result of actions taken by third-party users of the Website. All parts of the Website that contain user-generated material are under the control of the relevant user, for whom the Website is provided as an online service by the operator. Under 18 U.S.C. § 2257(h)(2)(B)(v) and 47 U.S.C. § 230(c), the Website's operator may delete materials appearing on the Website as the result of actions taken by the Website's users, which materials are considered, in the operator's sole discretion, to be indecent, obscene, defamatory, or inconsistent with the policies and terms of use for the Website.

**Exemption: Content Produced by Website Operator**
 To the extent that any images or videos appear on the Website, for which the Website's operator may be considered the "producer," those images and videos are exempt from the requirements of 18 U.S.C. § 2257 and 28 C.F.R. Part 75 for one or more of the following reasons: (1) the produced images or videos do not portray any sexually explicit conduct defined in 18 U.S.C. § 2256(2)(A); (2) the produced images or videos do not portray depictions of the genitals or pubic area created after July 27, 2006; (3) the produced images or videos do not portray simulated sexually explicit activity occurring after the effective date of 18 U.S.C. § 2257A; or (4) the produced images or videos were created before July 3, 1995.
 `;
