import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setMenuVisible } from '../state/uiSlice';
import Logo from '../assets/icons/logo.svg';
import User from '../assets/icons/user.svg';
import Media from '../assets/icons/media.svg';
import Email from '../assets/icons/email.svg';
import Analytics from '../assets/icons/analytics.svg';
import Subscribers from '../assets/icons/subscribers.svg';
import { decodeToken } from 'react-jwt';

const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menuVisible = useSelector((state) => state.ui.menuVisible);

  const pages = [
    { title: 'Profile', path: '/dashboard/profile', image: User },
    { title: 'Media', path: '/dashboard/media', image: Media },
    { title: 'Campaign', path: '/dashboard/campaign', image: Email },
    {
      title: 'Subscribers',
      path: '/dashboard/subscribers',
      image: Subscribers,
    },
    { title: 'Analytics', path: '/dashboard/analytics', image: Analytics },
    { title: 'Admin', path: '/dashboard/admin', image: null },
  ];

  return (
    <div
      className={`${
        menuVisible ? 'flex' : 'hidden'
      } lg:flex flex-col justify-between h-full sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-cbf-primary`}
    >
      {localStorage.getItem('auth_token') ? (
        <>
          <div>
            <div className="text-lg">
              <div className="p-2.5 mt-1 flex items-center">
                <div className="rounded-full bg-white">
                  <img src={Logo} className="w-[48px]" />
                </div>

                <div className="font-semibold text-white ml-3">
                  Could Be Fans
                </div>
              </div>
            </div>
            {pages.map((page) => {
              if (page.title == 'Admin') {
                const user = decodeToken(localStorage.getItem('auth_token'));
                if (user.access_level == 0 || !user.access_level) {
                  return null;
                }
              }
              return (
                <div
                  className={`p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white hover:text-cbf-primary hover:fill-cbf-primary text-white ${
                    page.path == location.pathname ? 'bg-gray-100/20' : ''
                  }`}
                  onClick={() => {
                    dispatch(setMenuVisible(false));
                    navigate(page.path);
                  }}
                  key={page.title}
                >
                  <img src={page.image} className="h-full" />
                  <span className="text-md ml-4 font-bold">{page.title}</span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col">
            <div
              className="flex items-center text-white cursor-pointer mb-4"
              onClick={() => {
                navigate('/dashboard/help');
              }}
            >
              <span className="ml-4">Help</span>
            </div>
            <div
              className="flex items-center text-white cursor-pointer mb-4"
              onClick={() => {
                navigate('/dashboard/account');
              }}
            >
              <span className="ml-4">Account Settings</span>
            </div>
            <div
              className="flex items-center text-white cursor-pointer mb-4"
              onClick={() => {
                localStorage.removeItem('auth_token');
                dispatch(setMenuVisible(false));
                navigate('/dashboard/login');
              }}
            >
              <span className="ml-4">Logout</span>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="text-gray-100 text-xl">
            <div className="p-2.5 mt-1 flex items-center">
              <h1 className="font-bold text-gray-200 text-[15px] ml-3">
                Could Be Fans
              </h1>
            </div>
            <div className="my-2 bg-gray-100 h-[1px]"></div>
          </div>
          <div
            className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white hover:text-cbf-primary text-white"
            onClick={() => {
              navigate('/dashboard/login');
            }}
          >
            <span className="text-[15px] ml-4 text-gray-200 font-bold">
              Login
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
