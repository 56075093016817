import React, { useMemo } from 'react';
import { Chart } from 'react-charts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip);

const stringToRandom = (string, salt) => {
  let seed = salt;
  for (let i = 0; i < string.length; i++) {
    seed += string.charCodeAt(i);
  }
  const x = Math.sin(seed) * 10000;
  return (x - Math.floor(x)) * 255;
};

const PieChart = ({ title, data, xAxis, yAxis }) => {
  if (!data) return null;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: title,
      },
    },
  };
  const labels = data.map((d) => d[xAxis]);
  const backgroundColors = data.map(
    (d) =>
      `rgba(${stringToRandom(d[xAxis], 0)}, ${stringToRandom(
        d[xAxis],
        7
      )}, ${stringToRandom(d[xAxis], 13)}, 0.2)`
  );

  console.log(backgroundColors);

  const chartData = {
    labels,
    datasets: [
      {
        label: yAxis,
        data: data.map((d) => d[yAxis]),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return <Pie options={options} data={chartData} />;
};

export default PieChart;
