import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InactiveStep from './InactiveStep';
import SetupCampaign from './SetupCampaign';
import SetupMedia from './SetupMedia';
import SetupOnlyFans from './SetupOnlyFans';
import SetupProfilePicture from './SetupProfilePicture';
import SetupVerification from './SetupVerification';

const steps = [
  {
    title: 'Upload a profile picture',
    number: 1,
    component: SetupProfilePicture,
  },
  {
    title: 'Upload your email content',
    number: 2,
    component: SetupMedia,
  },
  {
    title: 'Connect your OnlyFans',
    number: 3,
    component: SetupOnlyFans,
  },
  {
    title: 'Verification check',
    number: 4,
    component: SetupVerification,
  },
  {
    title: 'Build your campaign',
    number: 5,
    component: SetupCampaign,
  },
];

const Setup = () => {
  const profile = useSelector((state) => state.user.profile);
  const mediaItems = useSelector((state) => state.media.items);

  const currentStep = (() => {
    if (!profile || !mediaItems) return 1;
    if (!profile.media_id) {
      return 1;
    } else if (mediaItems.length < 6) {
      return 2;
    } else if (!profile.only_fans) {
      return 3;
    } else if (mediaItems?.filter((item) => item.status == 'LIVE').length < 5) {
      console.log(mediaItems.filter((item) => item.status == 'LIVE'));
      return 4;
    } else {
      return 5;
    }
  })();

  console.log('CURRENT STEP', currentStep);

  return (
    <main className="main mb-10 p-4 max-w-2xl w-full flex flex-col">
      <div className="flex flex-col mt-20 gap-[48px]">
        {steps.map((step) => {
          if (step.number == currentStep) {
            return (
              <step.component
                key={step.number}
                title={step.title}
                number={step.number}
              ></step.component>
            );
          } else {
            return (
              <InactiveStep {...step} completed={step.number < currentStep} />
            );
          }
        })}
      </div>
    </main>
  );
};

export default Setup;
