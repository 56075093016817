import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { verify } from '../api/subscribe';
import { reportView } from '../api/view';
import Button from '../components/util/Button';
const reasons = [
  'Media involves minors',
  'Media involves animals',
  'Media made me uncomfortable',
  'Media infringes on a copyright',
  'Other',
];

const Report = ({}) => {
  const params = useParams();
  const viewId = params.id;
  const [loading, setLoading] = useState(false);
  const [userReason, setUserReason] = useState();
  const [userContent, setUserContent] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userContent.length == 0) {
      return;
    }
    if (userContent.length < 25) {
      return setError(`At least ${25 - userContent.length} more characters`);
    }
    setError(null);
  }, [userContent]);

  const submit = async () => {
    setLoading(true);
    try {
      await reportView(viewId, userReason, userContent);
      setSubmitted(true);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col">
      <div className="p-4">
        <div className="font-semibold mt-10 text-xl">Report</div>
        {!submitted ? (
          <>
            <div className="text-gray-700">
              We take content reports and user safety extremely seriously.
            </div>
            <select
              className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-lg text-gray-500 block mt-2"
              value={userReason ? userReason : ''}
              onChange={(event) => {
                setUserReason(event.target.value);
              }}
            >
              <option value="">Reason for report</option>
              {reasons.map((reason) => {
                return (
                  <option
                    key={reason}
                    className="whitespace-pre-wrap text-ellipsis overflow-hidden"
                    value={reason}
                  >
                    {reason}
                  </option>
                );
              })}
            </select>
            <textarea
              type="text"
              className="w-full rounded-xl p-3 border border-gray-300 border-[1px] text-gray-500 block mt-2"
              value={userContent}
              onChange={(event) => {
                setUserContent(event.target.value);
              }}
              placeholder="Please describe why this content violates policies"
            />
            <Button
              title="Submit"
              type="action"
              className="w-full mt-4"
              disabled={!userReason || userContent.length <= 25}
              onClick={() => {
                submit();
              }}
              loading={loading}
            />
            {error && <div className="text-red-500">{error}</div>}
          </>
        ) : (
          <div className="">Your report has been submitted.</div>
        )}
      </div>
    </main>
  );
};

export default Report;
