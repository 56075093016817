import React from 'react';
import MediaSelect from '../../assets/icons/media-select.svg';
const SelectMediaButton = ({ onClick }) => {
  return (
    <button
      className="w-full flex flex-col items-center p-4 border-2 rounded-xl border-gray-300 border-dashed mt-2"
      onClick={() => {
        onClick();
      }}
    >
      <img src={MediaSelect} className="w-[40px] h-[40px]" />
      <p className="font-semibold text-cbf-primary">
        Select media from library
      </p>
    </button>
  );
};

export default SelectMediaButton;
