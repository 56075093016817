import axios from 'axios';
import { apiUrlForPath } from './api';

export const getMedia = async () => {
  return axios.get(apiUrlForPath('media'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  });
};

export const uploadMedia = async (files) => {
  if (!files || files.length == 0) throw 'No file';

  const medias = [];
  for (const file of files) {
    const data = new FormData();
    data.append('file', file);
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/media',
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      }
    );
    medias.push(response.data);
  }
  return medias;
};

export const voteMedia = async (mediaId, vote, subscriptionId) => {
  return axios.post(process.env.REACT_APP_API_URL + `/media/vote/${mediaId}`, {
    vote,
    subscriptionId,
  });
};

export const mediaUrl = (media) => {
  return `${process.env.REACT_APP_S3_URL}/${media.file_name}`;
};
