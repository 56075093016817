import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedia, uploadMedia } from '../../api/media';
import Image from '../../assets/icons/image-icon.svg';
import Button from '../../components/util/Button';
import { setLoading } from '../../state/uiSlice';
import InactiveStep from './InactiveStep';
import { updateUser } from '../../api/profile';
import { setProfile } from '../../state/userSlice';
import { setMediaItems } from '../../state/mediaSlice';
import MediaSelection from '../../components/MediaSelection';
import MediaAgreement from '../../components/util/MediaAgreement';
import MediaView from '../../components/MediaView';

const SetupMedia = ({ title, number }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const mediaItems = useSelector((state) => state.media.items);
  const [files, setFiles] = useState([]);
  const inputFiles = useRef(null);
  const [fileError, setFileError] = useState(null);
  const [mediaAgreement, setMediaAgreement] = useState(false);

  const removeFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  };

  const uploadNewMedia = async () => {
    dispatch(setLoading(true));
    try {
      await uploadMedia(files);
      const response = await getMedia();
      dispatch(setMediaItems(response.data));
      setFiles([]);
    } catch (e) {
      alert(e);
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const campaignMediaItems = mediaItems.filter(
    (item) => item.id != profile.media_id && item.status == 'LIVE'
  );

  return (
    <div>
      <InactiveStep title={title} number={number} completed={false} />
      <div className="flex gap-2 items-center justify-center mt-4">
        {Array(5)
          .fill(false)
          .map((item, index) => {
            return (
              <div
                className={`h-[4px] w-full ${
                  index < files?.length + campaignMediaItems?.length
                    ? 'bg-cbf-primary'
                    : 'bg-gray-200'
                }`}
                key={index}
              ></div>
            );
          })}
      </div>
      <div className="text-gray-700 text-[16px] mt-2">
        You'll need at least 5 pieces of media before you set up your profile
        and create your campaign. Upload them here and CouldBeFans will review
        them.
      </div>
      <div
        className="w-full flex gap-2 rounded-md border-gray-300 border-[1px] p-2 mt-6 cursor-pointer flex justify-center"
        onClick={() => {
          inputFiles.current.click();
        }}
      >
        <img src={Image} />
        <p className="font-semibold text-sm text-gray-700">Add new media</p>
      </div>
      {files?.length + campaignMediaItems?.length > 0 && (
        <div className="mt-6">
          <div className="font-semibold text-[18px] text-gray-900">
            Pending Review
          </div>
          <div className="text-[14px] text-gray-600">
            This media won't be available until it's reviewed and approved by
            CouldBeFans.
          </div>
          <div className="grid grid-cols-3 gap-[1px] mt-4">
            {campaignMediaItems.map((item) => {
              return (
                <div
                  className="w-full object-cover max-h-[150px] aspect-square"
                  key={item.id}
                >
                  <MediaSelection item={item} />
                </div>
              );
            })}
            {files.map((file, index) => {
              console.log('file', file);
              return (
                <div
                  className="w-full object-cover max-h-[150px] aspect-square"
                  onClick={() => {
                    removeFile(index);
                  }}
                  key={index}
                >
                  <MediaView
                    mediaUrl={URL.createObjectURL(file)}
                    mimeType={file.type}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {files?.length > 0 && (
        <>
          <MediaAgreement setCompletedAgreement={setMediaAgreement} />
          <Button
            type="action"
            disabled={
              files?.length + campaignMediaItems?.length < 5 || !mediaAgreement
            }
            title="Continue"
            onClick={() => {
              uploadNewMedia();
            }}
            className="w-full mt-6"
          />
        </>
      )}
      {fileError && <div className="text-red-500">{fileError}</div>}

      <input
        type="file"
        id="file"
        accept=".jpeg,.jpg,.png,.gif,.mov,.mp4,.mpeg"
        ref={inputFiles}
        style={{ display: 'none' }}
        onChange={(event) => {
          for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 1e8) {
              return setFileError('File cannot exceed 100MB');
            } else if (
              !event.target.files[i].type.includes('image/') &&
              !event.target.files[i].type.includes('video/')
            ) {
              return setFileError('File must be image, GIF, or video');
            }
          }
          setFiles([...files, ...event.target.files]);
          event.target.value = null;
        }}
        multiple
      />
    </div>
  );
};

export default SetupMedia;
