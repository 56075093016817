import React from 'react';
import Feature from './Feature';

import Automated from './assets/automated.svg';
import Analytics from './assets/analytics.svg';
import Lock from './assets/lock.svg';
import Scalable from './assets/scalable.svg';

const Features = () => {
  return (
    <section className="w-full p-4 lg:p-20 flex flex-col items-center">
      <div className="max-w-[500px] lg:max-w-none">
        <div className="text-cbf-primary font-semibold">Features</div>
        <div className="text-gray-900 font-semibold text-[32px]">
          Why CouldBeFans?
        </div>
        <div className="text-gray-600 text-[20px] mt-4">
          CouldBeFans is built specifically for creators to grow their audience.
          It's designed to be easy to set up, powerful behind the scenes, and to
          convert customers seamlessly.
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 max-w-[500px] lg:max-w-none">
        <Feature
          icon={Automated}
          title="Automated"
          content="Set up your email campaign once and we'll do the rest."
        />
        <Feature
          icon={Analytics}
          title="Analytics"
          content="Understand how your customers are converting from your campaign."
        />
        <Feature
          icon={Lock}
          title="Safe"
          content="All emails are verified to make sure no one is receiving emails they dont want."
        />
        <Feature
          icon={Scalable}
          title="Scalable"
          content="Whether you're a small creator or have millions of followers, campaigns work at any size."
        />
      </div>
    </section>
  );
};

export default Features;
