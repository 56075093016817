import React, { useRef, useEffect } from 'react';

const MediaView = ({
  mediaUrl,
  mimeType,
  blurred = false,
  autoPlay = true,
}) => {
  const videoRef = useRef();

  useEffect(() => {
    if (!mimeType.includes('video')) return;

    videoRef.current?.load();
    console.log(videoRef.current);
    console.log('loading video');
  }, [mediaUrl]);

  if (!mediaUrl) return null;

  return (
    <div className="w-full h-full flex flex-col items-center relative">
      {mimeType.includes('image') ? (
        <img
          className={`w-full h-full object-contain ${blurred && 'blur-xl'}`}
          src={mediaUrl}
        />
      ) : (
        <video
          controls="controls"
          preload="metadata"
          className={`w-full h-full object-cover ${blurred && 'blur-xl'}`}
          autoPlay={autoPlay}
          muted
          ref={videoRef}
        >
          <source
            src={mediaUrl}
            type={mimeType.includes('quicktime') ? 'video/mp4' : mimeType}
          />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default MediaView;
