import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditingCampaignIndex } from '../state/campaignSlice';
import Email from '../assets/icons/email-accent.svg';
import Edit from '../assets/icons/pencil.svg';
import MediaSelection from './MediaSelection';

const CampaignItem = ({ index }) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.campaign.items[index]);

  return (
    <div className="border-gray-200 border-[1px] rounded-xl flex flex-col md:flex-row h-[340px] overflow-hidden">
      <div className="h-3/5 md:h-full w-full">
        <MediaSelection item={item.media} />
      </div>
      <div className="h-2/5 md:h-full w-full p-4 flex flex-col justify-center relative">
        <button
          className="absolute rounded-lg border-[1px] border-gray-200 top-2 right-2 p-2"
          onClick={() => {
            dispatch(setEditingCampaignIndex(index));
          }}
        >
          <img src={Edit} className="w-[20px]" />
        </button>
        <div className="flex gap-2">
          <img className="w-[16px]" src={Email} />
          <div className="font-medium text-gray-600 text-lg">
            Email {index + 1}
          </div>
        </div>
        <div className="font-semibold text-cbf-primary mt-5">{item.title}</div>
        <div className="font-regular">{item.caption}</div>
      </div>
    </div>
  );
};

export default CampaignItem;
