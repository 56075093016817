import React from 'react';

const Feature = ({ icon, title, content }) => {
  return (
    <div className="w-full rounded-lg drop-shadow-md bg-gray-100 p-4">
      <img src={icon} className="mb-4" />
      <div className="text-gray-900 font-semibold text-[20px]">{title}</div>
      <div className="text-gray-600 text-[16px]">{content}</div>
    </div>
  );
};

export default Feature;
