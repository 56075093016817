import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../state/uiSlice';
import {
  adminGetUnverifiedMedia,
  adminPutMediaVerified,
} from '../../api/admin';
import MediaView from '../../components/MediaView';
import { mediaUrl } from '../../api/media';
import Button from '../../components/util/Button';

const AdminMedia = () => {
  const dispatch = useDispatch();

  const [media, setMedia] = useState([]);

  const loadMedia = async () => {
    dispatch(setLoading(true));
    try {
      const response = await adminGetUnverifiedMedia();
      setMedia(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const verifyMedia = async (mediaId, accepted) => {
    dispatch(setLoading(true));
    try {
      const response = await adminPutMediaVerified(mediaId, accepted);
      setMedia(
        media.filter((item) => {
          return item.id != mediaId;
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    loadMedia();
  }, []);

  return (
    <div className="w-full">
      <div className="w-full grid grid-cols-2 md:grid-cols-3">
        {media.map((item) => {
          return (
            <div className="flex flex-col mb-4">
              <MediaView
                mediaUrl={mediaUrl(item)}
                mimeType={item.mime_type}
                autoPlay={false}
              />
              <div className="flex items-center justify-center mt-2 gap-2">
                <Button
                  title="Accept"
                  type="action"
                  onClick={() => {
                    verifyMedia(item.id, true);
                  }}
                />
                <Button
                  title="Reject"
                  type="cancel"
                  onClick={() => {
                    verifyMedia(item.id, false);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button
        className="w-full mt-10"
        type="action"
        title="Load more media"
        onClick={() => {
          loadMedia();
        }}
      />
      <div className="text-sm text-center">
        You must finish the media above before continuing
      </div>
    </div>
  );
};

export default AdminMedia;
