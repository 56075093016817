import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedia } from '../api/media';
import MediaSelection from '../components/MediaSelection';
import { setMediaItems } from '../state/mediaSlice';
import { setLoading } from '../state/uiSlice';
import Image from '../assets/icons/image-icon.svg';
import UploadMedia from '../components/UploadMedia';
import MediaView from '../components/MediaView';

const Media = () => {
  const dispatch = useDispatch();
  const mediaItems = useSelector((state) => state.media.items);

  const [files, setFiles] = useState(null);
  const [fileError, setFileError] = useState(null);
  const inputFiles = useRef(null);

  const loadMediaItems = async () => {
    dispatch(setLoading(true));
    console.log('getting media');
    try {
      const response = await getMedia();
      dispatch(setMediaItems(response.data));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (mediaItems) return;
    loadMediaItems();
  }, []);

  if (files?.length > 0 || fileError) {
    return (
      <UploadMedia
        files={files}
        setFiles={setFiles}
        fileError={fileError}
        setFileError={setFileError}
      />
    );
  }

  return (
    <main className="main mb-10 w-full max-w-2xl mx-auto p-4">
      <div className="">
        Media is used for your profile picture and as the content of your email
        campaigns.{' '}
        <strong>You'll need at least 5 pieces of media before</strong> you can
        set up your profile and create your campaign. Upload them here and
        CouldBeFans will review them.
      </div>
      <div
        className="w-full flex gap-2 rounded-md border-gray-300 border-[1px] p-2 mt-10 cursor-pointer flex justify-center"
        onClick={() => {
          inputFiles.current.click();
        }}
      >
        <img src={Image} />
        <p className="font-semibold text-sm text-gray-700">Add new media</p>
      </div>
      <div className="font-semibold text-gray-700 mt-10">Verified Media</div>
      <div className="grid grid-cols-3 lg:grid-cols-6 gap-[1px] mt-4">
        {mediaItems
          ?.filter((item) => item.status == 'LIVE')
          .map((item) => {
            return (
              <div
                className="w-full shadow-lg aspect-square flex justify-center items-center overflow-hidden cursor-pointer"
                key={item.id}
              >
                <MediaSelection
                  item={item}
                  onSelection={() => {
                    return;
                  }}
                />
              </div>
            );
          })}
      </div>
      <div className="font-semibold text-gray-700 mt-10">Pending Review</div>
      <div className="text-gray-600 text-sm">
        This media won't be available in campaigns until it's reviewed and
        approved by CouldBeFans
      </div>
      <div className="grid grid-cols-3 lg:grid-cols-6 gap-[1px] mt-4">
        {mediaItems
          ?.filter((item) => item.status != 'LIVE')
          .map((item) => {
            return (
              <div
                className="w-full shadow-lg aspect-square flex justify-center items-center overflow-hidden cursor-pointer"
                key={item.id}
              >
                <MediaSelection
                  key={item.id}
                  item={item}
                  onSelection={() => {
                    return;
                  }}
                />
              </div>
            );
          })}
      </div>
      <input
        type="file"
        id="file"
        accept=".jpeg,.jpg,.png,.gif,.mov,.mp4,.mpeg"
        ref={inputFiles}
        style={{ display: 'none' }}
        onChange={(event) => {
          console.log(event.target.files);
          for (let i = 0; i < event.target.files.length; i++) {
            if (event.target.files[i].size > 1e8) {
              return setFileError('File cannot exceed 100MB');
            } else if (
              !event.target.files[i].type.includes('image/') &&
              !event.target.files[i].type.includes('video/')
            ) {
              return setFileError('File must be image, GIF or video');
            }
          }
          setFiles(event.target.files);
        }}
        multiple
      />
    </main>
  );
};

export default Media;
