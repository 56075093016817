import axios from 'axios';
import { apiUrlForPath } from './api';

export const getView = async (id) => {
  return axios.get(apiUrlForPath(`view/${id}`));
};

export const reportView = async (id, reason, content) => {
  return axios.post(apiUrlForPath(`view/report/${id}`), {
    reason,
    content,
  });
};
