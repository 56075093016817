export default `
**Innumerable LLC Privacy Policy for California Residents**

**Effective Date: 3/9/2023**

**Last Updated on: 3/9/2023**

This **Privacy Policy for California Residents** supplements the information contained in Innumerable LLC's Privacy Policy and applies solely to all visitors, users, and others who reside in the State of California ("consumers" or "you"). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA), and any terms defined in the CCPA have the same meaning when used in this Policy.

**Information We Collect**

Our Website collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (" **personal information**"). Personal information does not include:

- Publicly available information from government records.
- Deidentified or aggregated consumer information.
- Information excluded from the CCPA's scope, like:
  - health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA), clinical trial data, or other qualifying research data; and
  - personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.

In particular, our Website has collected the following categories of personal information from consumers within the last 12 months:

| **Category** | **Examples** | **Collected** |
| --- | --- | --- |
| A. Identifiers. | A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers. | YES |
| B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)). | A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.Some personal information included in this category may overlap with other categories. | YES |
| C. Protected classification characteristics under California or federal law. | Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information). | YES |
| D. Commercial information. | Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies. | NO |
| E. Biometric information. | Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data. | NO |
| F. Internet or other similar network activity. | Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement. | YES |
| G. Geolocation data. | Physical location or movements. | NO |
| H. Sensory data. | Audio, electronic, visual, thermal, olfactory, or similar information. | NO |
| I. Professional or employment-related information. | Current or past job history or performance evaluations. | NO |
| J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)). | Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records. | NO |
| K. Inferences drawn from other personal information. | Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes. | NO |

Our Website obtains the categories of personal information listed above from the following categories of sources:

- Directly from you. For example, from forms you complete or products and services you purchase.
- Indirectly from you. For example, from observing your actions on our Website.
- From third parties. For example, from our business partners.

**Use of Personal Information**

We may use or disclose the personal information we collect for one or more of the following purposes:

- To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment. We may also save your information to facilitate new orders.
- To provide, support, personalize, and develop our Website, products, and services.
- To create, maintain, customize, and secure your account with us.
- To process your requests.
- To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.
- To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).
- To help maintain the safety, security, and integrity of our Website, products and services, databases and other technology assets, and business.
- For testing, research, analysis, and product development, including to develop and improve our Website, products, and services.
- To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
- As described to you when collecting your personal information or as otherwise stated in the CCPA.
- To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our Website users is among the assets transferred.

We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.

**Sharing Personal Information**

We may share your personal information by disclosing it to a third party for a business purpose. We only make these business purpose disclosures under written contracts that describe the purposes, require the recipient to keep the personal information confidential, and prohibit using the disclosed information for any purpose except performing the contract. In the preceding 12 months, Company has disclosed personal information for a business purpose to the categories of third parties indicated in the chart below.

We do not sell personal information. In the preceding 12 months, Company has not sold personal information.

| **Personal Information Category** | **Category of Third-Party Recipients** |
| --- | --- |
| **Business Purpose Disclosures** | **Sales** |
| A: Identifiers. | Service Providers | None |
| B: California Customer Records personal information categories. | Service Providers | None |
| C: Protected classification characteristics under California or federal law. | None | None |
| D: Commercial information. | None | None |
| E: Biometric information. | None | None |
| F: Internet or other similar network activity. | Data analytic providers and Internet cookie data recipients, like Google Analytics | None |
| G: Geolocation data. | None | None |
| H: Sensory data. | None | None |
| I: Professional or employment-related information. | None | None |
| J: Non-public education information. | None | None |
| K: Inferences drawn from other personal information. | None | None |

**Your Rights and Choices**

The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.

_ **Right to Know and Data Portability** _

You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months ("right to know"). Once we receive your request and confirm your identity (see Exercising Your Right to Know or Delete), we will disclose to you:

- The categories of personal information we collected about you.
- The categories of sources for the personal information we collected about you.
- Our business or commercial purpose for collecting or selling that personal information.
- The categories of third parties with whom we share that personal information.
- If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
  - sales, identifying the personal information categories that each category of recipient purchased; and
  - disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.
- The specific pieces of personal information we collected about you (also called a data portability request).

_ **Right to Delete** _

You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions ("right to delete"). Once we receive your request and confirm your identity (see Exercising Your Rights to Know or Delete), we will review your request to see if an exception allowing us to retain the information applies.

We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:

1. Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.
2. Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
3. Debug products to identify and repair errors that impair existing intended functionality.
4. Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
5. Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 _et. seq._).
6. Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.
7. Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
8. Comply with a legal obligation.
9. Make other internal and lawful uses of that information that are compatible with the context in which you provided it.

We will delete or deidentify personal information not subject to one of these exceptions from our records and will direct our service providers to take similar action.

_ **Exercising Your Rights to Know or Delete** _

To exercise your rights to know or delete described above, please submit a request by either:

- Emailing us at [privacy@couldbefans.com](mailto:privacy@couldbefans.com).
- Visiting [https://couldbefans.com/ccpa-request](https://couldbefans.com/ccpa-request).

Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal information.

You may only submit a request to know twice within a 12-month period. Your request to know or delete must:

- Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.
- Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.

We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.

You do not need to create an account with us to submit a request to know or delete. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.

We will only use personal information provided in the request to verify the requestor's identity or authority to make it.

_ **Response Timing and Format** _

We will confirm receipt of your request within 10-business days. If you do not receive confirmation within the 10-day timeframe, please contact us.

We endeavor to substantively respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing.

If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.

Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.

We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

**Non-Discrimination**

We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:

- Deny you goods or services.
- Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
- Provide you a different level or quality of goods or services.
- Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.

However, we may offer you certain financial incentives permitted by the CCPA that **can result** in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information's value and contain written terms that describe the program's material aspects. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time.

**Other California Privacy Rights**

California's "Shine the Light" law (Civil Code § 1798.83) allows users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make that request, please send an email to [privacy@couldbefans.com](mailto:privacy@couldbefans.com).

**Changes to Our Privacy Policy**

We reserve the right to amend this privacy policy at our discretion and at any time. When we make changes to this privacy policy, we will post the updated notice on the Website and update the notice's effective date. **Your continued use of our Website after the posting of changes constitutes your acceptance of those changes**.

**Contact Information**

If you have any questions or comments about this notice, the ways in which Innumerable LLC collects and uses your information described here and in the Privacy Policy, your choices and rights regarding that use, or wish to exercise your rights under California law, please do not hesitate to contact us at:

**Website** : [www.couldbefans.com](http://www.couldbefans.com/)

**Email** : [privacy@couldbefans.com](mailto:privacy@couldbefans.com)

If you need to access this Policy in an alternative format due to having a disability, please contact us at [privacy@couldbefans.com](mailto:privacy@couldbefans.com).
`;
