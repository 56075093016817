import React from 'react';
import ReactMarkdown from 'react-markdown';
import Markdown from './USC2257Markdown';

const USC2257 = () => {
  return (
    <main className="main w-full mb-10 p-4 min-h-[400px] flex justify-center">
      <ReactMarkdown className="prose">{Markdown}</ReactMarkdown>
    </main>
  );
};

export default USC2257;
