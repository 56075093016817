import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProfile } from '../api/profile';
import { subscribe } from '../api/subscribe';
import UserHeader from '../components/UserHeader';
import Button from '../components/util/Button';
import { validate } from 'react-email-validator';
import Email from '../assets/icons/email-pulse.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import Checkbox from '../components/util/Checkbox';
import ReactGA from 'react-ga4';

const Subscribe = ({}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [profile, setProfile] = useState(null);
  const [email, setEmail] = useState('');
  const [over18, setOver18] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const loading = useSelector((state) => state.ui.loading);
  const [error, setError] = useState(null);

  useEffect(() => {
    const username = params.username;
    (async () => {
      dispatch(setLoading('OPAQUE'));
      try {
        const response = await getProfile(username);
        console.log(response);
        setProfile(response.data);
      } catch (e) {
        if (e.response.status == 404) {
          return setError(404);
        }
        console.log(e);
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [params]);

  const submit = async () => {
    dispatch(setLoading(true));
    try {
      const response = await subscribe(email, profile.id);
      ReactGA.event({
        category: 'conversion',
        action: 'subscribe',
        label: profile.only_fans,
      });
      console.log(response);
      setSubmitted(true);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (error == 404) {
    return (
      <main className="main w-full h-full mb-10 max-w-2xl flex flex-col items-center justify-center">
        <div className="font-semibold text-cbf-primary text-xl">Oops!</div>
        <div className="text-gray-900 mt-4">This user does not exist</div>
      </main>
    );
  }

  if (profile && !profile.campaign_live) {
    console.log(profile);
    return (
      <main className="main w-full mb-10 max-w-2xl flex flex-col items-center">
        <UserHeader profile={profile} />
        <div className="font-semibold text-[24px] text-gray-800 text-center mt-6">
          {profile && `${profile.only_fans}`}
        </div>
        <div className="text-gray-600 text-center mt-10">
          @{profile.only_fans} is not currently running a campaign. Check back
          later to see if it's gone live!
        </div>
      </main>
    );
  }

  const isEmailValid = validate(email);

  return (
    <main className="main w-full mb-10 max-w-2xl flex flex-col items-center">
      <UserHeader profile={profile} />
      <div className="font-semibold text-[24px] text-gray-800 text-center mt-6">
        {profile && `${profile.only_fans}`}
      </div>
      <div className="w-full mt-4 px-4 mb-4">
        {submitted ? (
          <div className="drop-shadow-md p-2 bg-white border-[1px] border-gray-100">
            <img src={Email} className="w-[40px]" />
            <div className="font-semibold text-gray-900">
              Verification email is on its way!
            </div>
            <div className="text-gray-600">
              If you don't see it, double check your spam folder. Remove it from
              your spam filters so you receive all the free content coming your
              way!
            </div>
          </div>
        ) : (
          <>
            <div className="text-sm text-center mt-2">
              Submit your email for 5 pieces of my{' '}
              <span className="font-semibold text-cbf-primary">
                exclusive content
              </span>{' '}
              you won't find anywhere else.
            </div>
            <input
              className="border-2 mt-2 rounded-lg p-2 w-full accent-cbf-primary"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Email"
            />
            <div className="flex gap-4 mt-2 mb-4 font-medium text-gray-700">
              <Checkbox
                value={over18}
                onChange={() => {
                  setOver18(!over18);
                }}
              />
              <p
                onClick={() => {
                  setOver18(!over18);
                }}
              >
                I certify that I am at least 18 years of age or older and have
                reached the age of majority in the jurisdiction where I reside
              </p>
            </div>
            <div className="flex gap-4 mt-2 mb-4 font-medium text-gray-700">
              <Checkbox
                value={termsAgreed}
                onChange={() => {
                  setTermsAgreed(!termsAgreed);
                }}
              />
              <p
                onClick={() => {
                  setTermsAgreed(!termsAgreed);
                }}
              >
                By clicking "Subscribe for free content" I hereby agree to our{' '}
                <a
                  href="/terms-of-use"
                  target="_blank"
                  className="text-cbf-primary underline"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Terms of Use
                </a>
              </p>
            </div>
            <Button
              title="Subscribe for free content!"
              type="action"
              className="w-full"
              disabled={!over18 || !isEmailValid || !termsAgreed}
              onClick={() => {
                submit();
              }}
              loading={loading}
            />
          </>
        )}

        <div className="text-lg font-semibold text-gray-800 mt-8">
          Hey CouldBeFans!
        </div>
        <div className="text-gray-600 text-sm">
          If you want to get a feel for what I share, sign up! Here's what
          you'll get with CouldBeFans:
          <ul className="list-disc px-8 py-4">
            <li>
              Special photos and videos sent right to your inbox{' '}
              {profile && profile.campaign_interval / 1000 / 60 / 60 == 24
                ? 'each day for 5 days'
                : 'each week for 5 weeks'}
            </li>
            <li>Emails sent with discrete subject lines</li>
            <li>
              An external link in each email so you don't accidentally open
              anything exciting in the wrong place ;)
            </li>
          </ul>
        </div>
        <div className="flex flex-col">
          <div className="text-xl font-semibold text-gray-800 text-center mt-6">
            Already wanting more?
          </div>
          <a
            href={profile?.only_fans_link}
            target="_blank"
            className="text-center w-full button font-semibold px-6 py-3 bg-cbf-primary rounded-lg text-white mt-6"
          >
            Join my OnlyFans
          </a>
          <div className="flex justify-center items-center gap-4 mt-4">
            <button className="font-semibold text-cbf-primary">
              Terms of Use
            </button>
            <button className="font-semibold text-cbf-primary">
              Privacy Policy
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Subscribe;
