import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';

const MediaAgreement = ({ setCompletedAgreement }) => {
  const [over18, setOver18] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    setCompletedAgreement(over18 && agreed && consent);
  }, [over18, agreed, consent]);

  return (
    <>
      <div className="flex items-center gap-4 mt-2 mb-4 font-medium text-gray-700">
        <Checkbox
          value={over18}
          onChange={() => {
            setOver18(!over18);
          }}
        />
        <p
          onClick={() => {
            setOver18(!over18);
          }}
        >
          I certify under penalty of perjury that I have verified the identity
          and age of all persons depicted in the content to ensure that all
          persons depicted are adults, and I can provide supporting documents on
          request.
        </p>
      </div>
      <div className="flex items-center gap-4 mt-2 mb-4 font-medium text-gray-700">
        <Checkbox
          value={consent}
          onChange={() => {
            setConsent(!consent);
          }}
        />
        <p
          onClick={() => {
            setConsent(!consent);
          }}
        >
          I certify under penalty of perjury that I have obtained and am keeping
          on record written consent from all persons depicted in the content to
          be uploaded specific to the following: (1) consent to be depicted in
          the content; (2) consent to allow for the public distribution of the
          content and to upload the content to the Website; and (3) if the
          content will be made available for downloading by other users, consent
          to have the content downloaded.
        </p>
      </div>
      <div className="flex items-center gap-4 mt-2 mb-4 font-medium text-gray-700">
        <Checkbox
          value={agreed}
          onChange={() => {
            setAgreed(!agreed);
          }}
        />
        <p
          onClick={() => {
            setAgreed(!agreed);
          }}
        >
          I certify I have read and agree to the{' '}
          <a
            href="/terms-of-use"
            target="_blank"
            className="text-cbf-primary underline"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Terms of Use Agreement
          </a>{' '}
          and the{' '}
          <a
            href="/terms-of-use"
            target="_blank"
            className="text-cbf-primary underline"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Terms of Service.
          </a>
        </p>
      </div>
    </>
  );
};

export default MediaAgreement;
