import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import LoadingOverlay from '../components/util/LoadingOverlay';

const Dashboard = () => {
  return (
    <div className="w-full h-full flex flex-col font-sans">
      <Header></Header>
      <div className="w-full lg:pl-[300px] flex flex-col items-center">
        <Outlet />
      </div>

      <Menu />
    </div>
  );
};

export default Dashboard;
