import React from 'react';
import { ReactComponent as Verified } from '../assets/icons/verified.svg';
import PulseIcon from './util/PulseIcon';
import Play from '../assets/icons/play.svg';

const MediaSelection = ({ item, onSelection = () => {} }) => {
  const mediaUrl = `${process.env.REACT_APP_S3_URL}/${item.file_name}`;
  return (
    <div
      className="w-full h-full flex flex-col items-center relative bg-cbf-back"
      key={item.id}
      onClick={() => {
        onSelection();
      }}
    >
      <div className="w-full h-full overflow-hidden">
        {item.mime_type.includes('image') ? (
          <img className="h-full w-full object-cover" src={mediaUrl} />
        ) : (
          <video preload="metadata" className="w-full h-full object-cover">
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      <div className="absolute top-2 right-2 w-[20px] h-[20px]">
        {item.status == 'LIVE' && (
          <Verified className="z-100 w-full h-full fill-cbf-primary" />
        )}
      </div>
      {item.mime_type.includes('video') && (
        <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center">
          <PulseIcon className="w-[40px] h-[40px]">
            <div className="w-full h-full flex items-center justify-center">
              <img src={Play} className="ml-1 w-[16px] h-[16px]" />
            </div>
          </PulseIcon>
        </div>
      )}
    </div>
  );
};

export default MediaSelection;
