export default `**Complaint Policy**

**Last Updated:**  **3/9/2023**

# This policy sets out our complaint policy for [couldbefans.com](http://www.couldbefans.com/) ("Website"). This policy forms part of your agreement with us.

1.
# Applicability

# Whether or not you are a user of the Website, you can use this policy to alert us to any complaint that you have relating to the Website.

1.
# How to Make a Complaint

# If you have a complaint about the Website (including any complaint about content appearing on the Website or the conduct of a user), please send your complaint to help@couldbefans.com, including your name, address, contact details, a description of your complaint, and, if your complaint relates to content, the URL for the content to which your complaint relates. If you cannot contact us by email, please write to us at the mailing address identified in Section 7.

1.
# Complaints of Illegal or Nonconsensual Content

# After receiving your complaint of illegal or nonconsensual content under Section 2, (1) we will take those steps as we consider to be appropriate to investigate your complaint within a timeframe that is appropriate to the nature of your complaint; (2) if we require further information or documents from you, we will contact you to let you know; (3) we will in good faith investigate your complaint within seven (7) business days; (4) if we are satisfied that the content is unlawful or nonconsensual, we will immediately remove that content, and we will notify you of our decision by email or other electronic message; and (5) if we are satisfied that the content is not unlawful or nonconsensual, we will notify you of our decision by email or other electronic message. Any dispute regarding our determination that content is nonconsensual will be submitted by us to a neutral arbitration association at our expense.

1.
# Complaints of Copyright Infringement

# Complaints related to copyright infringement must be submitted to our copyright agent in accordance with our DMCA Policy, and we will respond to copyright infringement complaints as set out in that policy.

1.
# All Other Complaints

# After receiving any other complaint under Section 2, (1) we will take those steps as we consider to be appropriate to investigate your complaint within a timeframe that is appropriate to the nature of your complaint; (2) if we require further information or documents from you, we will contact you to let you know; and (3) we will in good faith take those actions as we consider appropriate to deal with the issue that your complaint has raised. If you have complained about the content that appears on the Website and we are satisfied that the content otherwise breaches the Terms of Use, we will act promptly to remove that content. We are not required to inform you of the outcome of your complaint.

1.
# Unjustified or Abusive Complaints

# If you are a user of the Website, you state that you will not make any complaint under this policy that is wholly unjustified, abusive, or made in bad faith. If we determine that you have breached this warranty, we may suspend or terminate your user account.

1.
# Contact Information

# The Website is owned and operated by Innumerable LLC, a Delaware limited liability company. You can write to us at 1200 Fitzgerald Drive #1140 Pinole, CA 94564 or at help@couldbefans.com. All notices of copyright infringement claims should be sent to the copyright agent designated in our DMCA Policy in the manner and by the means set out in it.
`;
